/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

@mixin hidden {
	display: none !important;
	visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

@mixin visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */

@mixin invisible {
	visibility: hidden;
}

@mixin reset-cs {
	margin: 0;
	padding: 0;
	background: none;
	border: 0;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: 16px;
	list-style: none;
}

@mixin transform-cs($arguments...) {
  -webkit-transform: $arguments;
  -moz-transform: $arguments;
  -o-transform: $arguments;
  -ms-transform: $arguments;
  transform: $arguments;
}

@mixin translate3d-cs($x:0, $y:0,$z:0) {
  @include transform-cs(translate3d($x, $y, $z));
}
