.desktop-nav__interior-page-header-content__wrapper {
	@include mobile-padding(0,0);
	background:#fff url("../img/thin-top-shadow.png") repeat-x top left;
}
.desktop-nav__interior-page-header-content {
	display:none;
	position:relative;
	padding:16px 0 0;
	max-width:1000px;
	margin:0 auto;
	h1 {
		@include font-size(3.5,1.07);
		padding:3px 0 13px;
		width:69%;
		@include respond-min(660px) {width:73%;}
		@include respond-min(915px) {width:81%;}
	}
	@include respond-min(600px) {
		display:block;
	}
}

.interior-page-header-home-link {
	@include font-size(1.2,0);
	@include inline-block;
	color:#9a918a;
	position:relative;
	top:-8px;
}