.grid-icon-buttons {
	@include clearfix;
	margin-top: 20px;
	margin-bottom: -15px;
}

.grid-icon-button {
	display: inline-block;
	width: 90px;
	height: 90px;
	border-radius: 5px;
	background: white;
	padding: 5px;
	margin-right: 15px;
	margin-bottom: 12px;
	.icon {
		color: $orange;
		transition: color 0.15s;
	}
	.text {
		@include avenir-bold;
		font-size: 11px;
		line-height: 1;
		margin-top: 8px;
		color: #969089;
		transition: color 0.15s;
	}
	.centered {
		display: block;
		position: absolute;
		width: 100%;
		text-align: center;
		top: 50%;
		transform: translate(0, -50%);
	}
	&:hover {
		.icon {
			color: $orange-hover;
		}
		.text {
			color: #726e6a;
		}
	}
}

.grid-icon-button__inner {
	display: block;
	width: 100%;
	height: 100%;
	color: #969089;
	border-radius: 5px;
	box-shadow: inset -2px 0px 2px 0px rgba(0, 0, 0, 0.2);
	text-align: center;
	position: relative;
}

@media (min-width: 481px) and (max-width: 600px) {
	.grid-icon-button {
		width: 22.375%;
		margin-right: 3.5%;
		&:nth-child(4n+4) {
			margin-right: 0;
		}
	}
}

@media (min-width: 361px) and (max-width: 480px) {
	.grid-icon-button {
		width: 31%;
		margin-right: 3.5%;
		&:nth-child(3n+3) {
			margin-right: 0;
		}
	}
}

@media (max-width: 360px) {
	.grid-icon-button {
		width: 48%;
		margin-right: 4%;
		&:nth-child(2n+2) {
			margin-right: 0;
		}
	}
}

