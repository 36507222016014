.image-nav-list {
  @include clearfix;
  @include mobile-padding(1em, 1em);
  h1 {
    font-size: 1.125em;
    line-height: 1.333;
    margin-bottom: .66em;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin-top: 1em;
  }
  @include respond-min(480px) {
    li {
      padding: 1%;
      width: 50%;
    }
  }
  @include respond-min(600px) {
    li {
      padding: 2%;
      width: 33%;
    }
  }
  @include respond-min(840px) {
    > ul {
      flex-wrap: nowrap;
    }
    li {
      padding: 1%;
      width: auto;
    }
  }
}

.image-nav-list__image {
  @include image-border-wrapper;
  box-shadow: 0 0 34px 34px rgba(213, 210, 199, .35);
  margin-bottom: 14px;
  @include respond-min(840px) {
    box-shadow: 0 0 10px 10px rgba(213, 210, 199, .35);
  }
}

.image-nav-list__link,
.image-nav-list__blurb {
  @include clarendon-regular;
  font-size: .8125em;
  line-height: 1.5;
  color: #78736b;
  a {
    color: $brand-color
  }
  p {
    margin-top: .5em;
  }
}
