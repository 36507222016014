.homepage-buttons-intro {
	@include clearfix;
	@include paper-bg;
	@include mobile-padding(27px, 35px);
	border-top:1px solid #d3d1c6;
	@include respond-min(480px) {
		@include mobile-padding(0, 35px);
		border-top:none;
	}
	@include respond-min(720px) {
		@include mobile-padding(2.25em, 35px);
	}
	@include respond-min(1028px) {
		padding:36px 0;
	}
}
.homepage-buttons {
	@include clearfix;

	ul {
		@include clearfix;
		margin:0 -5px 0 -5px;
		padding:0;
		list-style:none;
	}
	li {
		@include size(48% 108px);
		@include font-size(1.4, 1.3);
		@include avenir-bold;
		float:left;
		background:#fff;
		border-radius:7px;
		padding:4px;
		margin:1%;
	}

	a {
		display:block;
		width:100%;
		height:100%;
		color:#969089;
		border-radius:5px;
		box-shadow:inset -2px 0px 2px 0px rgba(0, 0, 0, 0.2);
		text-align:center;
		padding:4px;
	}
	img {
		margin:3px 0 2px;
	}
	span {display:block;}
	.single-line {line-height:2;}
	.double-line {line-height:1;}

	@include respond-min(514px) {
		ul {margin:0;}
		li {
			@include size(24% 114px);
			margin:0;
			& + li {margin-left:1%;}
		}
	}

	@include respond-min(720px) {
		float:right;
		width:32.5%;
		li {
			@include size(48% 121px);
			margin:0;
			& + li {margin-left:0;}
			&.button-1 {margin:0 4% 5% 0;}
			&.button-3 {margin:0 4% 5% 0;}
		}
	}

}
.homepage-intro {
	margin-top:24px;
	letter-spacing:-.005em;
	@include font-size(1.6, 1.35);
	h2 {
		margin:0;
		@include font-size(2, 1.1);
	}

	@include respond-min(720px) {
		@include font-size(2, 1.35);
		float:left;
		width:63.8%;
		margin-top:0;
		h2 {@include font-size(2.2, 1.1);}
		p {margin-top:.75em;}
	}


}
.homepage-intro__button {
	margin:2em 0 0 0 !important;
	a {@include ct-button;}

	@include respond-min(720px) {
		a {@include ct-button($font-size:.61em, $display:inline);}
	}

}