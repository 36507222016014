.homepage-tweets {
	@include mobile-padding(0,14px);
	ul {
		@include clearfix;
		margin:0;
		padding:0;
		list-style:none;
	}
	li {
		margin:0;
		padding:0;
		@include respond-min(600px) {
			float:left;
			width:32%;
			padding-bottom:28px;
			& + li {
				margin-left:2%;
			}
		}
	}
	li + li {
		margin-top:1.9em;
		@include respond-min(600px) {
			margin-top:0;
		}
	}
}

.homepage-tweets__title {
	@include clearfix;
	margin-bottom:.75em;
	h2 {
		@include font-size(1.6,1.25);
	}
	iframe {
		margin-top:.5em;
	}
	@include respond-min(600px) {
		margin-bottom:1em;
		h2 {display:inline;}
		iframe {
			float:right;
			margin-top:0;
		}
	}
}

.homepage-tweet {
	@include font-size(1.3,1.4);
	@include clarendon-regular;
	background:#fff;
	color: #78736b;
	border-radius:5px;
	padding:20px;
	box-shadow: 6px 6px 3px rgba(0,0,0,.04);
	p {margin:0;}
	a {
		font-family:georgia;
		font-weight:bold;
	}
}

.homepage-tweet__triangle {
	@include size(32px 28px);
	@include retina-image("../img/tweet-triangle", 32px 28px, jpg);
	position:relative;
	top:0;
	left:18px;
}

.homepage-tweet__user-info {
	@include clearfix;
	padding-top:5px;
}

.homepage-tweet__user-info__image {
	float:left;
	padding-right:.75em;
	img {
		width:40px;
		height:auto;
		border-radius:4px;
	}
}

.homepage-tweet__user-info__text {
	h3 {
		@include clarendon-italic;
		@include font-size(1.5,1.1);
	}
	span {
		@include avenir-bold;
		@include font-size(1.1,1.1);
		color: #78736b;
		text-transform:uppercase;
	}
}

.homepage-tweet__user-info a {
	display:block;
}