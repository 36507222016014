.mobile-nav {
	@include orange-paper-bg;
	@include mobile-padding(1em, 1em);
	display:none;
	text-transform:lowercase;
	box-shadow: inset 0px -2px 12px 5px rgba(0,0,0,.33);
	color: #fff;
	-webkit-font-smoothing:antialiased;

	a {color:inherit;}

	.content {
		@include clearfix;
		opacity:0;
		position:relative;
		top:-50px;
	}
}

.mobile-nav__main-nav {
	@include clearfix;
	@include avenir-bold;
	li {
		float:left;
		width:50%;
		@include respond-min(600px) {
			width:31%;
			padding:0 1%;
		}
		@include respond-min(640px) {
			width:auto;
			padding:0;
			& + li {margin-left:1.5em;}
		}
	}
}

.mobile-nav__utility-nav {
	@include font-size(1.4, 1.3);
	text-align:center;
	li {display:inline;}
	a {
		@include inline-block;
		padding:1em .375em 0;
	}
	@include respond-min(536px) {
		width:50%;
		float:right;
	}
}


.mobile-nav__site-search {
	margin:12px 0 0;
	font-size:0;
	line-height:0;
	padding-bottom:5px;
	border-bottom:1px solid #fff;
	input {
		@include placeholder {color:#ccc;}
		@include font-size(1.6, 1.1);
		width:85%;
		background:none;
		border:none;
		padding:0;
		margin:0;
		color:#fff;
	}
	button {
		@include font-size(1.6, 1.1);
		width:15%;
		background:none;
		border:none;
		padding:0;
		margin:0;
		color:#fff;
		position:relative;
	}
	@include respond-min(536px) {
		width:50%;
		float:left;
	}

}

.mobile-interior-title {
	// @include orange-paper-bg;
	background: #fff;
	border-top: 2px solid #faeadc;
	border-bottom: 1px solid #e8e4d8;
	@include mobile-padding(12px, 12px);
	position:relative;
	-webkit-font-smoothing:antialiased;
	@include respond-min(600px) {
		display:none;
	}

	h1 {
		@include font-size(2.4, 1.1);
		color:$brand-color;
	}
	.mobile-interior-title__bottom-shadow {
	width:100%;
	height:29px;
	//background: url("../img/noisy-shadow-bottom.png") repeat-x;
	position:absolute;
	bottom:0;
	left:0;
	}
}
