.main-content-well__page-subtitle h1 {
	font-size: 1.25em;
	line-height: 1.2;
	margin-bottom: .7em;
}

.main-content-well__bordered-intro-image {
	@include image-border-wrapper;
	margin-bottom: 20px;
}

.main-content-well__big-intro {
	// font-size:1.125em;
	// line-height:1.388;
	// @include respond-min(480px) {
	// 	font-size:1.4375em;
	// 	line-height:1.347;
	// }	
}

.ct-button {
	@include ct-button($display: inline-block, $font-size: 0.875em);
}

.main-content-well {
	font-size: 1em;
}

.main-content-well__rich-text {
	@include font-size(1.8, 1.375);
	h2 {
		font-size: 1.125em;
		line-height: 1.15;
		margin-top: 2em;
	}
	p {
		margin: 1em 0;
		&:first-of-type {
			margin-top: 0;
		}
	}

}

.side-navigation {
	margin: 56px -14px 0 -14px;
	padding: 22px 14px 0;
	border-top: 1px solid #d6d4ca;
	text-transform: lowercase;
	@include respond-min(768px) {
		margin: 0;
		padding: 0;
		border-top: none;
		li li a {
			color: #444;
		}
	}
	li a {
		@include avenir-regular;
		font-size: 1em;
		line-height: 1.15em;
		padding: 8px 0;
		color: #444;
		display: block;
		border-bottom: 1px dashed #d3d1c6;
		&:hover {
			color: $brand-color;
		}
	}
	li.overview > a {
		@include avenir-bold;
		padding-bottom: 20px;
	}
	li.here > a,
	li.parent-here > a {
		@include avenir-bold;
		color: $brand-color !important;
	}
	li li {
		padding-left: 1em;
		font-size: .75em;
	}
}

.search-results {
	margin: 1.5em 0;
	padding: 0;
	list-style: none;
	h4 {
		margin-bottom: .33em;
	}
	li {
		& + li {
			margin-top: 1em;
		}
	}
}

.pagination {
	@include clarendon-regular;
	@include font-size(1.4, 1.3);
	@include clearfix;
	position: relative;
	margin: 0;
	padding: 1em 0 0;
	list-style: none;
	text-align: center;
	li {
		display: inline;
	}
	a {
		@include inline-block;
		padding: 0 .5em;
		min-width: 27px;
		height: 27px;
		text-align: center;
		line-height: 25px;
		border-radius: 4px;
		border: 1px solid #d5d2c7;
		&.active {
			@include clarendon-bold;
			border: none;
			color: #9a918a;
		}
		&.other-page,
		&.page-previous,
		&.page-next {
			&:hover {
				background: #0071ae;
				color: #fff;
				border: 1px solid #0071ae;
			}
		}
	}
}

.page-previous {
	position: absolute;
	left: 0;
	top: 1em;
}

.page-next {
	position: absolute;
	right: 0;
	top: 1em;
}

.right-column .org-links__set i {
	color: #8c8379;
}

.main-content-well__rich-text table,
.main-content-well__rich-text td,
.main-content-well__rich-text th {
	//	border-color:#dadada !important;
}

.main-content-well__rich-text table th {
	@include avenir-bold;
	-webkit-font-smoothing: antialiased;
	background-color: $brand-color;
	color: #fff;
	padding: 10px;
	text-align: left;
}

.main-content-well__rich-text table td {
	padding: 3px 10px;
}

blockquote {
	margin: 0;
	padding: 0 0 0 1.25em;
	border-left: 5px solid $brand-color;
	color: #666;
}

.rich-text-body__right-aligned-image-caption,
.rich-text-body__left-aligned-image-caption {
	@include font-size(1.3, 1.3);
	color: #777;
	img {
		max-width: 100%;
	}
}

@include respond-min(600px) {

	.rich-text-body__right-aligned-image-caption {
		float: right;
		width: 30%;
		margin: 1em 0 1em 1em;
	}
	.rich-text-body__left-aligned-image-caption {
		float: left;
		width: 30%;
		margin: 1em 1em 1em 0;
	}
}

.freeform-row {
	label {
		text-transform: lowercase;
	}
	.unit {
		padding: 0 !important;
	}
	input[type="text"],
	textarea {
		width: 80% !important;
	}
	input[type="submit"],
	button[type="submit"] {
		@include ct-button;
	}
	.error-message {
		color: #dd0000;
	}
}

.inner-table-wrapper {
	min-width: 100%;
	overflow: auto;
	overflow-x: auto;
}
