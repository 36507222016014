.page-intro {
	@include mobile-padding(1.5em, 1.5em);
	@include clearfix;
	position:relative;
	@include respond-min(480px) {
		@include mobile-padding(1em, 1em);
	}
}
.page-intro__content-wrapper {
	position:relative;
	max-width:1000px;
	margin:0 auto;	
}
