$transition-speed: 0.5s;
$transition-func: ease-in-out;

.news-stories-footer__wrapper {
	padding-top: 32px;
	padding-bottom: 49px;
	background-color: #dbd5c2;
	position: relative;
	overflow: hidden;
	@include respond-min(768px) {
		// @include mobile-padding(0, 0);
		padding: 0 0 40px 0;
	}
}

.news-stories-footer {
	@include clearfix;
	max-width: 1028px;
	padding-left: 14px;
	padding-right: 14px;
	margin: 0 auto;
	padding-bottom: 23px;

	h2 {
		@include font-size(1.6, 1.1);
		color: #464646;
		margin-bottom: 12px;
		-webkit-font-smoothing: antialiased;
	}
}

.news-stories-footer .ul {
	@include reset-cs;
	position: relative;
	height: 400px;
	margin-top: 32px;
	box-sizing: border-box;
}

.news-stories-footer .ul .li {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0 16px;
	box-sizing: border-box;
	cursor: pointer;
}

.news-stories-footer .ul .li:focus {
	outline: dotted 2px #0071ae;
}

.news-stories-footer .ul .li .p {
	color: white;
}

.news-stories-footer .ul .li .img {
	display: block;
	width: calc(100% - 32px);
	z-index: 1;
	position: absolute;
	left: 16px;
	top: 0;
	box-sizing: border-box;
}

.news-stories-footer .ul .li .text-content {
	position: relative;
	background: $orange-news;
	z-index: 2;
	padding: 0 16px 16px 16px;
}

.news-stories-footer .ul .li .p.headline {
	position: relative;
	
	text-align: center;
	text-transform: capitalize;
}

.news-stories-footer .ul .li .p.teaser {
	position: relative;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: bold;
	font-size: 20px;
	line-height: 22px;
}

.news-stories-footer .ul .li .headline-text {
	position: relative;
	z-index: 2;
	display: block;
	height: 112px;
	overflow: hidden;
}

.news-stories-footer .ul .li .p.teaser:before {
	content: "";
	display: block;
	position: absolute;
	top: -35px;
	left: 50%;
	height: 73px;
	width: calc(100% + 32px);
	background: $orange-news;
	border-radius: 50%;
	z-index: 2;
	@include transform-cs(skew(0) rotate(0) translateX(-50%));
}

.news-stories-footer .ul .li .p.teaser:after {
	content: "";
	display: block;
	width: 100%;
	height: 32px;
	position: absolute;
	bottom: 0;
	z-index: 3;
	background: -moz-linear-gradient(top,  rgba(228,111,7,0) 0%, rgba(228,111,7,1) 100%);
	background: -webkit-linear-gradient(top,  rgba(228,111,7,0) 0%,rgba(228,111,7,1) 100%);
	background: linear-gradient(to bottom,  rgba(228,111,7,0) 0%,rgba(228,111,7,1) 100%);
}

.news-stories-footer .ul .li .p.teaser-prompt {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0;
	margin-top: 8px;
	font-style: italic;
	font-size: 18px;
}

.news-stories-footer .ul .li .p.teaser-prompt:before {
	content: "";
	display: block;
	width: 24px;
	height: 24px;
	box-sizing: border-box;
	margin-right: 8px;
	background-image: url(../img/news-teaser-prompt.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.news-stories-footer .ul .li.init {
	display: none;
	opacity: 0;
}

.news-stories-footer .ul .li.pos-0 {
	@include translate3d-cs(-100%,0,0);
	@include transition (all $transition-speed $transition-func);
	opacity: 0;
	pointer-events: none;
}

.news-stories-footer .ul .li.pos-1 {
	@include translate3d-cs(0,0,0);
	@include transition (all $transition-speed $transition-func);
	opacity: 1;
}

.news-stories-footer .ul .li.pos-2 {
	@include translate3d-cs(100%,0,0);
	@include transition (all $transition-speed $transition-func);
	opacity: 1;
}

.news-stories-footer .ul .li.pos-3 {
	@include translate3d-cs(200%,0,0);
	@include transition (all $transition-speed $transition-func);
	opacity: 1;
}

.news-stories-footer .ul .li.pos-4 {
	@include translate3d-cs(300%,0,0);
	@include transition (all $transition-speed $transition-func);
	opacity: 0;
	pointer-events: none;
}

.buttons {
	display: flex;
	justify-content: center;
	padding-top: 32px;
	padding-bottom: 32px;
}

.buttons .button {
	@include reset-cs;
	background: $orange-news;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: 24px;
	font-size: 24px;
}

.buttons .button:before {
	display: block;
	content: "<";
	color: white;
}

.buttons .button + .button {
	margin-left: 32px;
}

.buttons .button + .button:before {
	content: ">";
}

.buttons .button:focus {
	outline: dotted 1px #0071ae;
}


@media only screen and (min-width: 544px) {
	.news-stories-footer .ul .li {
	    width: 50%;
	}
}
@media only screen and (min-width: 992px) {
	.news-stories-footer .ul .li {
	    width: 33.3%;
	}
}
