.page-intro__image-and-caption__image-wrapper {
	@include image-border-wrapper;
}

.page-intro__image-and-caption__caption {
	@include font-size(1.2, 1.333);
	color: #898278;
	p {margin: .5em 0 0;}
}

.program-intro {
	.page-intro__side-wrapper {
		padding-top: 10px;
	}
}

.page-intro__side-wrapper {
	&.page-intro__side-wrapper--before {
		display:none;
		@include respond-min(600px) {
			display:block;
		}
	}
	&.page-intro__side-wrapper--after {
		@include respond-min(600px) {
			display:none;
		}
	}
	@include respond-min(600px) {
		float:right;
		margin:0 0 1em 1em;
	}
}

.image-nav-list-page .page-intro__side-wrapper {
	display:block !important;
	margin-bottom:-1.5em;
	text-align:center;
	@include respond-min(480px) {margin-bottom:-1em;}
	@include respond-min(600px) {
		position:absolute;
		bottom:-1em;
		right:30px;
	}
}

.image-nav-list-page .page-intro__body {
	@include respond-min(600px) {
		padding-right:220px;
	}
}