.interior__page-body {
	font-size: 1em;
	line-height: 1.5;
}

.main__wrapper {
	.full-width & {
		background: #fff
	}
;
}

.interior--two-column,
.interior--three-column {
	padding: 26px 0;
	@include clearfix;
}

@media (max-width: 599px) {
	.interior--two-column,
	.interior--three-column {
		padding-top: 20px;
	}
	body.news-events {
		.interior--two-column,
		.interior--three-column {
			padding-top: 0;
		}
	}
}

@media (max-width: 1040px) {
	.interior--two-column,
	.interior--three-column {
		margin-left: 14px;
		margin-right: 14px;
	}
}

.main-content-well {
	@include clearfix;
}

.side-column-content {
	margin-top: 40px;
	margin-bottom: 40px;
}

.side-column-media-contact {
	@include font-size(1.3, 1.4);
	color: #7b746b;
	h2 {
		@include font-size(1.4, 1.3);
	}
}

@include respond-min(768px) {
	.main-content-well--two-column {
		float: right;
		width: 74.5%;
	}
	.side-column--two-column {
		float: left;
		width: 23%;
	}

	.three-column--two-thirds-wrapper {
		float: right;
		width: 72%;
	}

	.main-content-well--three-column {
		float: left;
		width: 71%;
	}
	.right-column--three-column {
		float: right;
		width: 24%;
		padding-top: 2em;
	}

	.side-column--three-column {
		float: left;
		width: 25%;
		padding-top: 2em;
	}

}

.large-intro-image {
	height: 235px;
	position: relative;
	overflow: hidden;
	.large-intro-image__inner {
		margin: 0 auto;
		max-width: 1200px;
		height: 235px;
		img {
			position: absolute;
			height: 235px;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 9;
		}
	}
	.large-intro-image__text-container {
		position: relative;
		max-width: 1028px;
		padding: 0 28px;
		height: 100%;
		z-index: 10;
		margin: 0 auto;
	}
	.large-intro-image__text {
		width: 285px;
		text-align: center;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		color: white;
		@include avenir-bold;
		@include font-size(2.4, 1.16);
		text-shadow: 2px 0 2px rgba(0, 0, 0, 0.55);
	}
	@media (min-width: 768px) {
		height: 320px;
		.large-intro-image__inner {
			height: 320px;
			img {
				height: 320px;
			}
		}
		.large-intro-image__text {
			text-align: left;
			left: auto;
			right: 14px;
			transform: translate(0, -50%);
			@include font-size(2.8, 1.16);
		}
	}
	@media (min-width: 1024px) {
		height: 366px;
		.large-intro-image__inner {
			height: 366px;
			img {
				height: 366px;
			}
		}
		.large-intro-image__text {
			@include font-size(3.1, 1.16);
		}
	}
}

.interior--sidebar {
	@include clearfix;
	margin: 37px auto 44px;
	max-width: 1028px;
	overflow: hidden;
	padding: 0 14px;
	margin-top: 20px;
	.right-sidebar {
		background: white;
		border-radius: 7px;
		padding: 17px 20px;
		margin-top: 30px;
		position: relative;
		p:first-child {
			margin-top: 0 !important;
		}
		p:last-child {
			margin-bottom: 0 !important;
		}
		h3 {
			@include font-size(2.2, 1.1);
			margin-bottom: 7px;
		}
		.sidebar-section {
			margin-bottom: 30px;
			p:first-of-type {
				margin-top: 0;
			}
		}
		.sidebar-footer {
			padding-top: 19px;
			margin-top: 30px;
			border-top: 1px solid #ddd;
		}
	}
	@media (min-width: 850px) {
		margin-top: 37px;
		.main-content-well {
			display: inline-block;
			width: calc(100% - 305px);
			float: left;
		}
		.right-sidebar {
			display: inline-block;
			width: 275px;
			float: right;
			margin-top: 0;
		}
		.sidebar-footer.fixed {
			position: absolute;
			left: 20px;
			bottom: 0;
			padding: 17px 0;
			width: calc(100% - 40px);
		}
	}
	@media (min-width: 950px) {
		.main-content-well {
			width: calc(100% - 355px);
		}
		.right-sidebar {
			width: 325px;
		}
	}
}
