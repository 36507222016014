.nav-lists {
	@include clearfix;
	margin-left:-1%;
	margin-right:-1%;
}

nav.simple-nav-list {
	background:#fff;
	border-radius:12px;
	padding:6px;
	margin-bottom:32px;
	h1 {
		@include font-size(1.8,1.1);
		padding:25px 23px 22px;
		border-bottom:1px solid #d3d1c6;
	}
	ul {
		padding:0 23px 15px;
	}
	a {
		display:block;
		padding-top:15px;
		&:after {
			content: " »";
		}
	}

	@include respond-min(480px) {
		float:left;
		margin:1%;
		width:48%;
	}
}

.simple-nav-list-wrapper {
	border:1px solid #d3d1c6;
	border-radius:7px;
}
