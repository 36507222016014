.desktop-hero {
	@include clearfix;
	display:none;
	position:relative;
	overflow:hidden;
	min-height:206px;
	@include respond-min(480px) {
		display:block;
	}
	@include respond-min(1000px) {
		overflow:visible;
	}
}
.desktop-hero__image-slide {
	display:block;
	float:left;
	width:180%;
	margin-left:-40%;
}

.hero-container {
	position: relative;
}

.hero-container .hero-navigation {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -40px;
	z-index: 3;
	height: 48px;
	justify-content: center;
	align-items: center;
	display: none;
}

.hero-container .hero-navigation.ready {
	display: flex;
}

.hero-container .hero-navigation button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: $orange-news;
	border: solid 2px white;
	border-radius: 10px;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	cursor: pointer;
	opacity: 0.5;
}

.hero-container .hero-navigation button.featured {
	opacity: 1;
}

.hero-container .hero-navigation button + button {
	margin-left: 1rem;
}

.slide-container {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 1s;
}

.slide-container.first {
	position: relative;
	opacity: 1;
	transition: opacity 1s;
}

.slide-container.ready {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 1;
	transition: opacity 1s;
}

.slide-container.ready.render {
	opacity: 1;
	z-index: 2;
	transition: opacity 1s;
}

.desktop-hero__content {
	@include position(absolute, 0 0px 0px 0);
	padding:0 14px 32px;
	width:42%;
	@include respond-min(540px) {
		padding:0 14px 48px;
	}
	@include respond-min(820px) {
		padding:0 14px 70px;
	}
}
.desktop-hero__content__blurb-and-button {
	@include font-size(1.4,1.3);
	display:none;
	color:#fff;
	@include respond-min(720px) {
		display:block;
	}
	@include respond-min(820px) {
		@include font-size(1.6,1.3);
	}
}
.homepage-cta.homepage-cta--desktop {
	@include clearfix;
	a {
		width:65%;
		float:right;
	}
}