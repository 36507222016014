.block {
	margin-bottom: 20px;
	&:last-of-type {
		margin-bottom: 30px;
	}
	&.heading {
		margin-top: 2em;
		margin-bottom: 1em;
		&:first-child {
			margin-top: 0;
		}
	}
	&.horizontal-line {
		display: block;
		border: 0;
		background-color: #fed850;
		width: 100%;
		height: 1px;
	}
}

.centered-image {
	text-align: center;
	img {
		border-radius: 6px;
	}
}
.body-text {
	ul ul, ol ol {
		margin: 0.25em 0;
	}
}
.aligned-image {
	img {
		border-radius: 6px;
	}
}
.image-caption {
	margin-top: 0.5em;
	font-size: 14px;
	line-height: 1.25;
	text-align: center;
}
@media (max-width: 640px) {
	.aligned-image {
		display: block;
		width: 100%;
		margin-bottom: 20px;
		text-align: center;
		img {
			margin: 0 auto;
		}
		.image-caption {
			max-width: 360px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
@media (min-width: 640px) {
	.aligned-image {
		width: 360px;
		&.alignment-left {
			float: left;
			margin: 0.25em 30px 20px 0;
		}
		&.alignment-right {
			float: right;
			margin: 0.25em 0 20px 30px;
		}
	}
}