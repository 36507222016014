.site-header__wrapper {
	background: #fff;
	@include respond-min(970px) {
		min-height: 115px;
	}
}

.site-header {
	@include clearfix;
	padding: 5px 12px 5px 9px;
	margin: 0 auto;
	@include respond-min(970px) {
		width: 1000px;
		padding: 0 0 14px;
		@media print {
			width: 100% !important;
		}
	}
}

.site-header__logo {
	@include inline-block;
	@include size(145px 57px);
	@include retina-image("../img/childrenstrust-logo", 145px 57px);
	@include hide-text;
	@include transition (all .25s ease-in-out);

	float: left;
	margin: 0;
	padding: 0;

	a {
		@include inline-block;
		@include size(175px 69px);
	}

	@include respond-min(680px) {
		@include size(216px 86px);
		@include retina-image("../img/childrenstrust-logo-lg", 216px 86px);
		margin-top: 9px;
		a {
			@include size(216px 86px);
		}
	}

	@include respond-min(1050px) {
		position: relative;
		left: -35px;
		@media print {
			left: 0 !important;
		}
	}

}

.site-header__show-mobile-nav {
	@include size(46px 40px);
	@include retina-image("../img/mobile-menu-button", 26px 20px);
	@include hide-text;
	float: right;
	border: none;
	padding: 10px;
	margin: 33px 0 0 0;
	position: relative;
	top: -10px;
	right: -10px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-origin: content-box;
	@include respond-min(600px) {
		display: none;
	}
}

.site-header__site-search,
.site-header__util-and-social,
.desktop-nav__wrapper {
	display: none;
}

@media (min-width: 600px) and (max-width: 969px) {
	.site-header {
		padding-bottom: 24px;
	}
}
@include respond-min(600px) {
	.site-header__site-search {
		@include font-size(0, 0);
		display: block;
		float: right;
		border-bottom: 1px solid #d5d2c7;
		margin-top: 9px;
		input, button {
			@include font-size(1.2, 1.1);
			background: none;
			border: none;
			padding: 0;
			margin: 0;
		}
		button {
			color: #7b746b;
		}
	}
	.site-header__util-and-social {
		display: block;
		float: right;
		clear: right;
	}
	.site-header__utility-nav {
		@include font-size(1.4, 1.1);
		float: right;
		clear: right;
		margin-top: 1em;
		li {
			float: left;
			& + li {
				margin-left: 1.35em;
			}
		}
		a {
			color: #7b746b;
			&:hover .text {
				text-decoration: underline !important;
			}
		}
		li.donate a {
			display: inline-block;
			background-color: #e46f07;
			border: 0;
			color: #fff;
			padding: 7px 12px 7px 12px;
			margin-top: -11px;
			border-radius: 5px;
			transition: color 0.15s, background 0.15s;
			@include avenir-bold;
    		&:hover {
				color: #fff;
				background: #b94408;
				.text {
					text-decoration: none !important;
				}
			}
		}
	}
	.site-header__social-nav {
		@include font-size(1.6, 1.1);
		margin-top: 18px;
		float: right;
		clear: right;
		li {
			@include inline-block;
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
		a {
			color: #7b746b;
			transition: color 0.15s;
			&:hover {
				color: #464646;
			}
		}

		.header-envelope {
			font-size: 24px; 
			width: 24px; 
			position: relative; 
			top: 4px;
		}
	}
}

@include respond-min(768px) {
	.site-header__utility-nav,
	.site-header__social-nav {
		float: left;
	}
	.site-header__utility-nav {
		margin-top: 21px;
	}
	.site-header__social-nav {
		margin-top: 18px;
		margin-left: 18px;
	}
	.site-header__site-search {
		margin-top: 30px;
	}
}
