@mixin font-size($size:1.6, $line:1.5){
  font-size:   ($size * 10) + px;
  font-size:   $size + rem;
  line-height: $line;
}

@mixin avenir-regular {
	font-family:'AvenirNextLTW01-Regular', helvetica, arial, sans-serif;
	font-weight: normal !important;
	font-style:normal !important;
}
@mixin avenir-italic {
	font-family:'AvenirNextLTW01-Italic', helvetica, arial, sans-serif;
	font-weight: normal !important;
	font-style:normal !important;
}
@mixin avenir-bold {
	font-family:'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
	font-weight: normal !important;
	font-style:normal !important;
}
@mixin avenir-bold-italic {
	font-family:'AvenirNextLTW01-BoldIta', helvetica, arial, sans-serif;
	font-weight: normal !important;
	font-style:normal !important;
}
@mixin clarendon-regular {
	font-family:'ClarendonTextW01-Regula 1097896', georgia, serif;
	font-weight: normal !important;
	font-style:normal !important;
}
@mixin clarendon-bold {
	font-family:'Clarendon Text W01 Bold', georgia, serif;
	font-weight: normal !important;
	font-style:normal !important;
}
@mixin clarendon-bold-italic {
	font-family:'ClarendonTextW01-BoldIt', georgia, serif;
	font-weight: normal !important;
	font-style:normal !important;
}
@mixin clarendon-italic {
	font-family:'ClarendonTextW01-Italic 1097339', georgia, serif;
	font-weight: normal !important;
	font-style:normal !important;
}

@mixin mobile-padding($padding-top:0, $padding-bottom:0) {
	padding: $padding-top 14px $padding-bottom;
}

@mixin ct-button($color: #fff, $border: #b94701, $bg: #e46e05, $font-size: 1em, $display: block) {
	@include avenir-bold;
	background:$bg;
	font-size: $font-size;
	border-radius:5px;
	border:1px solid $border;
	//box-shadow:inset 0 0 5px darken($border, 5%);
	text-align:center;
	display:$display;
	-webkit-font-smoothing:antialiased;
	color:$color;
	padding:.75em 1.2em;
	text-shadow:1px 0 rgba(0,0,0,.2);
	letter-spacing:.04em;
	@include transition (all .25s ease-in-out);
	&:hover {
		background:lighten($bg, 15%);
		//box-shadow:inset 0 0 5px lighten($border, 5%);
		//border:1px solid transparent;
	}
}

@mixin ct-button-inline($color: #fff, $border: #b94701, $bg: #e46e05, $font-size: 1em, $display: inline-block) {
	@include avenir-bold;
	background:$bg;
	font-size: 80%;
	border-radius:5px;
	border:1px solid $border;
	text-align:center;
	display:$display;
	-webkit-font-smoothing:antialiased;
	color:$color;
	padding:.125em 0.5em;
	text-shadow:1px 0 rgba(0,0,0,.2);
	letter-spacing:.04em;
	@include transition (all .25s ease-in-out);
	&:hover {
		background:lighten($bg, 15%);
		//box-shadow:inset 0 0 5px lighten($border, 5%);
		//border:1px solid transparent;
	}
}

@mixin image-border-wrapper {
	@include inline-block;
	background:#fff;
	padding:5px;
	max-width:100%;
	border-radius:6px;
	img {
		max-width:100%;
		height:auto;
		border-radius:6px;
	}
}

@mixin paper-bg {
	background-color: #f6f5f4;
}

@mixin orange-paper-bg {
	background-color: #cb8b3b;
}

@mixin brown-paper-bg {
	background-color: #8f867e;
}

$fix-mqs: false !default;

@mixin respond-min($width) {
	@if $fix-mqs {
		@if $fix-mqs >= $width {
			@content;	
		}
	}
	@else {
		@media (min-width: $width), print {
			@content;
		}
	}
}
@mixin respond-max($width) {
	@if $fix-mqs {
		@if $fix-mqs <= $width {
			@content;	
		}
	}
	@else {
		@media (max-width: $width), print {
			@content;
		}
	}
}

$old-ie: false !default;

@mixin old-ie {
	@if $old-ie {
		@content;
	}
}
