.top-cta-area {
	margin:0 14px .75em;
	@include font-size(1.6, 1.5);
	border-bottom:1px solid #f2c459;
	margin-bottom:.75em;
	p {margin-bottom:.75em;}
	a {
		color: $blue-link-color;
	}
	@include respond-min(480px) {
		display:none;
	}
}

.page-intro__cta-area {
	display:none;
	@include respond-min(480px) {
		display:block;
	}
}