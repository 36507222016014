.main-content-well__back-link {
	@include font-size(1.2, 1.3);
	margin-bottom: 1em;
	a {
		color: #9a918a;
	}
}

.photo-gallery-grid {
	padding: 0;
	margin: 0;
	list-style: none;

	font-size: 0;
	line-height: 0;

	margin-right: -4px;

	@include respond-min(600px) {
		margin-right: -13px;
	}

	li {
		@include inline-block;
		background: #fff;
		border-radius: 9px;
		padding: 4px;
		margin: 0 4px 4px 0;
		@include respond-min(600px) {
			margin: 0 13px 19px 0;
		}
	}
	img {
		border-radius: 7px;
		width: 132px;
		height: auto;
		@include respond-min(600px) {
			width: 212px;
		}
	}
}

.media-center__featured-video__title {
	h2 {
		@include clarendon-bold-italic;
		@include font-size(3, 1.133);
		margin-top: 24px;
	}
}

.media-center__featured-video__teaser {
	@include font-size(2.3, 1.347826087);
	p {
		margin: 6px 0 0;
	}
}

.media-center__featured-video__link {
	@include font-size(1.4, 1.3);
	p {
		margin-top: 14px;
	}
}

.page-list-container {
	background: #fff;
	margin: 2em -14px 0;
	&.no-top-margin {
		margin-top: 0 !important;
	}
	padding: 20px;
	box-shadow: 0 0 34px rgba(0, 0, 0, .2);
	@include respond-min(600px) {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		margin: 2em 0 0;
		&.no-top-margin {
			margin-top: 0 !important;
		}
	}
}

.page-list-container__header {
	h2 {
		@include font-size(2, 1.2);
		@include respond-min(600px) {
			display: inline;
		}
	}
	margin-bottom: 11px;
	@include respond-min(600px) {
		//		border-bottom:1px solid #f2c459;
		padding-bottom: 14px;
		margin-bottom: 0;
	}
}

.page-list-container__filter-options {
	@include clearfix;
	padding: 20px 0 15px;
	border-top: 1px solid #f2c459;
	border-bottom: 1px solid #f2c459;
	@include respond-min(600px) {
		display: none;
	}
}

.filter-options__label {
	@include avenir-bold;
	float: left;
	width: 20%;
	text-transform: uppercase;
	font-size: 11px;
	color: #9a918a;
	@include respond-min(600px) {
		display: inline;
	}
}

.filter-options {
	float: right;
	width: 75%;
	margin-bottom: 5px;
	select {
		width: 100%;
	}
}

body.media-center,
body.news-events {
	.side-navigation {
		margin-top: 0;
	}
	.interior--two-column {
		padding-bottom: 0 !important;
	}
}

.media-center__list-items {
	@include clearfix;
	margin: 0;
	padding: 0;
	list-style: none;
	border-bottom: 1px solid #f2c459;

	li {
		@include clearfix;
		padding: 24px 0;
		border-top: 1px solid #f2c459;
		body.media-center & {
			@include respond-min(600px) {
				float: left;
				height: 240px;
				width: 50%;
				padding: 3% 2%;
				&.odd {
					border-right: 1px solid #f2c459;
				}
			}
		}
		&.desktop-only {
			display: none;
			@include respond-min(600px) {
				display: list-item;
			}
		}
	}
}

.media-center__list-item__image {
	float: left;
}

.media-center__list-item__info {
	padding-left: 90px;
}

.media-center__list-item__type {
	@include font-size(1.3, 1.3);
	@include clarendon-italic;
	color: #a69b8f;
	margin-bottom: 6px;
	i {
		padding-left: 4px;
	}
}

.media-center__list-item__title {
	h3 {
		@include font-size(1.6, 1.2);
		margin-bottom: 0;
	}
}

.media-center__list-item__teaser {
	p {
		margin: 6px 0 0;
	}
}

.inline-filter-options {
	.media-center & {
		display: none;
	}
	padding-top: .25em;

	label {
		@include avenir-bold;
		text-transform: uppercase;
		font-size: 11px;
		color: #9a918a;
		padding-right: .75em;
	}

	.media-center & {
		@include respond-min(600px) {
			display: inline;
			float: right;
			label {
				padding-left: 2em;
			}
		}
	}
}

h3.dark {
	color: #464646;
}
h3.thin {
	@include avenir-regular;
	@include font-size(2.2, 1.1);
}