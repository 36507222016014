.grid-logos {
	@include clearfix;
	margin-top: 20px;
	margin-bottom: -15px;
	text-align: center;
}

.grid-logo {
	display: inline-block;
	margin-bottom: 15px;
	img {
		display: block;
		margin: 0;
		max-width: 100%;
		height: auto;
	}
	&:not(:last-child) {
		margin-right: 20px;
	}
}