.mobile-homepage-cta {
	@include mobile-padding(0, 28px);
	padding-top:1em;
	p {margin-top:0;}
	@include respond-min(480px) {
		@include paper-bg;
	}
	@include respond-min(720px) {
		display:none;
	}
}
.homepage-cta a {
	@include ct-button;
}