/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */


/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;}

/* Icon Fonts
*********************************/
/* Font-face Icons */
@font-face {
	font-family: 'flexslider-icon';
	src:url('../fonts/flexslider-icon.eot');
	src:url('../fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/flexslider-icon.woff') format('woff'),
		url('../fonts/flexslider-icon.ttf') format('truetype'),
		url('../fonts/flexslider-icon.svg#flexslider-icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {margin: 0; padding: 0;}
.flexslider .slides > li {display: none; -webkit-backface-visibility: hidden;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "\0020"; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
html[xmlns] .slides {display: block;}
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}

// /* FlexSlider Default Theme
// *********************************/
.flexslider {
	margin: 0;
	position: relative;
	zoom: 1;
	.slides { zoom: 1; }
}

.flex-viewport {
	@include transition(all 1s ease);
	max-height: 2000px;
	.loading & {max-height: 300px;}
}

.flex-direction-nav {
	width:100%;
	.flex-prev,
	.flex-next {
		@include size(29px 84px);
		@include hide-text;
		position:absolute;
		top:50px;
	}
	.flex-prev {
		@include retina-image("../img/flexslider-arrow-prev", 29px 84px);
		left:0;
		margin-left:-34px;
	}
	.flex-next {
		@include retina-image("../img/flexslider-arrow-next", 29px 84px);
		right:0;
		margin-right:-34px;
	}
}
