.gift-amounts {
	@include clearfix;
	margin: 21px 0;
	position: relative;
	&:not(.other-amount) {
		.gift-amount-other {
			display: none;
		}
	}
}

.gift-amount-boxes {
	margin-bottom: 15px;
}

.gift-amount {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	height: 80px;
	position: relative;
	label {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		@include avenir-bold;
		@include font-size(2.2, 80px);
		color: white;
		transition: background 0.15s;
		background: #9A928B;
		border-radius: 7px;
		cursor: pointer;
		height: 80px;
		&:hover {
			background: #7b746a;
		}
	}
	input[type="radio"] {
		visibility: hidden;
		width: 0;
		height: 0;
	}
	input:checked + label {
		background: #E46F07;
	}
	@media (max-width: 639px) {
		width: calc(33.33333% - 6px);
		margin-right: 9px;
		margin-bottom: 9px;
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
	@media (min-width: 640px) {
		width: calc(16.66667% - 7.5px);
		&:not(:last-child) {
			margin-right: 9px;
		}
	}
}

.gift-amount-other {
	display: block;
	width: 100%;
	height: 80px;
	position: absolute;
	left: 0;
	top: 0;
	.icon {
		position: absolute;
		z-index: 10;
		&.dollar-symbol {
			color: #4D4D4D;
			@include avenir-bold;
			@include font-size(3.6, 1);
			position: absolute;
			top: 22px;
			left: 27px;
		}
		&.cancel-other-amount {
			border: 0;
			background: transparent;
			margin: 0;
			padding: 0 20px;
			right: 0;
			color: #9A928B;
			font-size: 30px;
			line-height: 80px;
			transition: color 0.15s;
			cursor: pointer;
			&:hover {
				color: #7b746a;
			}
		}
	}
	input[type="number"] {
		position: relative;
		z-index: 9;
		background: white;
		border: 1px solid #9A928B;
		border-radius: 7px;
		height: 80px;
		width: 100%;
		@include font-size(3.6, 1);
		padding: 22px 64px;
		color: #444;
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
	@media (min-width: 640px) {
		width: calc(83.3333% - 1px);
	}
}

.donate-form {
	fieldset {
		margin-bottom: 20px;
		&.text input {
			margin-bottom: 12px;
		}
	}
	.required {
		color: #FC0006;
		@include avenir-regular;
	}
	.donate-form-label {
		display: block;
		@include font-size(1.6, 1.125);
		@include avenir-bold;
		margin-bottom: 7px;
	}
	input[type="text"],
	input[type="email"] {
		width: 100%;
		@include font-size(1.6, 28px);
		padding: 0 7px;
		border: 1px solid #ccc;
		background: white;
	}
	textarea {
		display: block;
		width: 100%;
		@include font-size(1.6, 22px);
		padding: 7px;
		border: 1px solid #ccc;
		background: white;
		height: 45px;
		min-height: 45px;
		resize: vertical;
	}
	.dedication-fields {
		padding-left: 40px;
		.dedication-fields__row {
			@include clearfix;
		}
		.dedication-field {
			margin-bottom: 12px;
			.donate-form-error {
				margin-top: 5px;
			}
		}
		.dedication-field-container {
			// height: 32px;
		}
		.dedication-fields__inner {
			background: #e8e8e8;
			padding: 20px 20px 8px 20px;
			@include clearfix;
			position: relative;
			&:before {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 11px 0 0 11px;
				border-color: transparent transparent transparent #e8e8e8;
				position: absolute;
				top: -11px;
				left: 0;
			}
		}
		.dedication-fields__type {
			select {
				font-size: 15px;
				width: 100%;
			}
		}
		.dedication-fields__name {
			input {
				width: 100%;
			}
		}
	}
	.donate-form__footer-text {
		@include font-size(1.6, 1.5);
		margin-top: 14px;
	}
	.donate-form-error {
		&.hidden {
			display: none;
		}
		color: #FC0006;
		@include font-size(1.6, 1.5);
		margin-bottom: 15px;
	}
	.donor-name-row {
		.donate-form-error {
			padding-top: 12px;
		}
		.donor-name-col:first-child {
			margin-bottom: 20px;
		}
	}
	@media (min-width: 640px) {
		.dedication-fields {
			.col {
				display: inline-block;
				float: left;
				margin-right: 5%;
			}
			.one-third {
				width: 25%;
			}
			.two-thirds {
				width: 70%;
			}
			.half {
				width: 47.5%;
			}
			.last {
				margin-right: 0;
			}
			&__city {
				width: 50%;
			}
			&__state {
				width: 10%;
			}
			&__zip-code {
				width: 30%;
			}
		}
		.donor-name-row {
			.donor-name-col {
				display: inline-block;
				float: left;
				width: 47.5%;
				margin-bottom: 0 !important;
				&:not(:last-child) {
					margin-right: 5%;
				}
			}
		}
	}
}

.donate-form-submit {
	background: #E46F07;
	border-radius: 7px;
	@include font-size(2.2, 32px);
	@include avenir-bold;
	border: 0;
	padding: 9px 50px;
	color: white;
	transition: background 0.15s;
	.loading-img {
		margin-right: 5px;
		display: none;
	}
	&:hover {
		background: #fa943c;
	}
	&.loading {
		background: #7b746a;
		padding-left: 32px;
		.loading-img {
			display: inline;
		}
		&:hover {
			background: #7b746a;
		}
	}
}

.checkbox-container {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 6px;
	cursor: pointer;
	@include font-size(1.6, 20px);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		border-radius: 3px;
		font-size: 14px;
		line-height: 20px;
		background: white;
		border: 1px solid #ccc;
		transition: all 0.15s;
		color: white;
		text-align: center;
	}
	&:hover input ~ .checkmark,
	&:focus input ~ .checkmark {
		background-color: #ccc;
	}
	input:checked ~ .checkmark {
		background: #E46F07;
		border-color: #E46F07;
		color: white;
	}
	&.large-checkbox {
		padding-left: 40px;
		@include font-size(2.2, 26px);
		.checkmark {
			width: 26px;
			height: 26px;
			border-radius: 5px;
			font-size: 18px;
			line-height: 26px;
		}
	}
}

.checkbox-instructions {
	padding-left: 40px;
	@include font-size(1.6, 1.5);
}

.donate-thank-you {
	.social-buttons {
		margin: 1.5em 0;
		padding: 0;
		li {
			display: inline-block;
			font-size: 20px;
			&:not(:last-child) {
				margin-right: 0.6em;
			}
		}
	}
	.social-buttons a,
	.subscribe-link {
		color: $orange;
		transition: color 0.15s;
		&:hover {
			color: $orange-hover;
			text-decoration: none;
		}
	}
	.subscribe-link {
		display: block;
		margin: 1.5em 0;
		.fa-envelope {
			font-size: 29px;
			margin-right: 8px;
		}
		.fa-envelope,
		.text {
			vertical-align: middle;
			line-height: 20px;
		}
		&:hover {
			.text {
				text-decoration: underline;
			}
		}
	}
}

.paypal-error {
	background: #FC0006;
	padding: 20px 25px;
	margin-bottom: 20px;
	h3 {
		margin-bottom: 10px;
		color: #fff;
	}
	p {
		color: #fff;
		@include font-size(1.6, 1.5);
		margin: 0;
	}
	a {
		color: #fff;
		text-decoration: underline;
	}
}