.interior-textsizing-and-sharing {
	@include clearfix;
	background:#fff;
}
.interior-textsizing-and-sharing--mobile {
	@include mobile-padding(0,0);
	background:#fff;
	@include respond-min(600px) {
		display:none;
	}
}
.interior-textsizing-and-sharing--desktop {
	@include position(absolute, 0 0px 0px 0);
	width:126px;
	font-size:0;
	line-height:0;
	&.no-sharing-controls {
		width:58px;
	}
}

.desktop-print-sizing-controls {
	@include inline-block;
	width:58px;
	padding-bottom:9px;
}

.textsizing-controls,
.sharing-controls,
.sharing-control {@include inline-block;}

.textsizing-controls--mobile {
	float:left;
	padding:13px 13px 8px 0;
	font-size:0;
	line-height:0;
}
.sharing-controls--mobile {
	float:left;
	padding:7px 0 0 10px;
	line-height:0;
}

.textsizing-controls--desktop {
	padding-top:13px;
}

.textsizing-controls--mobile {
	background: url("../img/text-sizing-border.png") no-repeat right bottom;
	line-height:0;
}
.sharing-controls--desktop {
	@include inline-block;
	background: url("../img/text-sizing-border-desktop.png") no-repeat left bottom;
	width:64px;
	padding:4px 0 13px 15px;
	line-height:0;
	position: relative;
	&:before {
		content: "share:";
		color:#8c8379;
		@include position(absolute, -15px 0 0 0);
		@include font-size(1.2,1.1);
	}	
}

.text-sizing-control,
.print-control {
	border:none;
	background:none;
	margin:0;
	padding:0;
	color:#8c8379;
}
.text-sizing-control {
	@include avenir-bold;
	@include font-size(1.5,1.1);
	border-bottom:1px solid #8c8379;
	& + .text-sizing-control {margin-left:6px;}
}
.print-control {
	@include font-size(1.2,1.1);
	i {@include font-size(1.6,1);}
}

.text-sizing-control-small {
	font-size:11px;
	font-size:1.1rem;
	position:relative;
	top:1px;
}
.text-sizing-control-regular {
	color:$brand-color;
	border-bottom:1px solid $brand-color;
}
.text-sizing-control-large {
	font-size:17px;
	font-size:1.7rem;
}

.sharing-control {
	@include font-size(1.8,1.8);
	a {
		color: #7b746b;
		margin: 0 2px;
	}
	.sharing-controls--desktop & + .sharing-control {margin-top:8px;}
}