/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

* {
	@include box-sizing(border-box);
}

html,
button,
input,
select,
textarea {
	color: $body-text-color;
}

html {
	font-size: 62.5%;
	line-height: 1.4;
	-ms-text-size-adjust: 100%; /* 2 */
	-webkit-text-size-adjust: 100%; /* 2 */
}

body {
	@include avenir-regular;
	@include paper-bg;
	@include font-size(1.6, 1.3);
	min-width: 280px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.container {
	overflow: hidden;
}

.cke_editable {
	padding: 1em;
}

main {
	max-width: 1000px;
	margin: 0 auto;
	overflow: visible;
}

a,.a {
	color: #0071ae;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	nav &:hover {
		text-decoration: none !important;
	}
}

[class*="button"] {
	a:hover {
		text-decoration: none !important;
	}
}

b,
strong {
	@include avenir-bold;
}

i,
em {
	@include avenir-italic;
}

i b,
i strong,
em b,
em strong,
b i,
b em,
strong i,
strong em {
	@include avenir-bold-italic;
}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

/*
 * A better looking default horizontal rule
 */

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */

audio,
canvas,
img,
video {
	vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
	resize: vertical;
}

/* ==========================================================================
   Browse Happy prompt
   ========================================================================== */

.browsehappy {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

abbr {
	text-decoration: none !important;
}

.ct-button-inline {
	@include ct-button-inline;
}