.top-image {
	img {width:100%;height:auto;}
}

.top-image--desktop {
	background:#fff;
	display:none;
	overflow:hidden;
	max-width:1000px;
	margin:0 auto;
	@include respond-min(1000px) {overflow:visible;}

	img {
		display:block;
		width:120%;
		margin-left:-10%;
		@media print {
			width:100% !important;
			margin-left:0 !important;
		}
	}
}

@include respond-min(480px) {
	.top-image--mobile {display:none;}
	.top-image--desktop {display:block;}
}
