.mobile-hero {
	@include clearfix;
	position:relative;
	min-height:212px;

	@include respond-min(480px) {
		display:none;
	}
}
.mobile-hero__image-slide {
	display:block;
	float:left;
	max-width:100%;
}
.mobile-hero__content {
	@include position(absolute, 0 0 0px 0px);
	padding:0 14px 16px;
}