.donate-box__cta {
	a {
		font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
		font-weight: normal !important;
		font-style: normal !important;
		background: #e46f07;
		font-size: 1em;
		border-radius: 5px;
		border: 1px solid #b94701;
		text-align: center;
		display: block;
		-webkit-font-smoothing: antialiased;
		color: #fff;
		padding: .75em 1.2em;
		text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
		letter-spacing: .04em;
		-webkit-transition: all 0.15s ease-in-out;
		-moz-transition: all 0.15s ease-in-out;
		transition: all 0.15s ease-in-out;
		width: 100%;
	}
	a:hover {
		background: #fb953b;
		text-decoration: none;
	}
}

@include respond-min(640px) {
	.main-content-well__donate-box {
		position: relative;
	}
	.donate-box__bg {
		z-index: 5;
	}
	.donate-box__overlay {
		display: block;
		position: absolute;
		top: 5px;
		left: 5px;
		z-index: 6;
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		border-radius: 6px;			
	}
	.donate-box__content {
		z-index: 10;
		position: absolute;
		top: 0;
		left: 54%;
		width: 40%;
		height: 100%;
		color: #fff;
	}
	.donate-box__text {
		display: block;
		margin-top: 35px;
		font-size: 18px;
		line-height: 26px;
		height: calc(100% - 140px);
		position: relative;
		&__inner {
			position: relative;
		  	top: 50%;
		  	transform: translateY(-50%);
		  	p {
		  		margin: 0;
		  	}
		}
	}
	.donate-box__cta {
		a {
			position: absolute;
			bottom: 40px;
			left: 0;
			
		}
	}	
}

@include respond-max(850px) {
	.donate-box__content {
		left: 46%;
		width: 48%;
	}
	.donate-box__overlay {
		background: -moz-linear-gradient(left,  rgba(0,0,0,0) 10%, rgba(0,0,0,1) 100%);
		background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 10%,rgba(0,0,0,1) 100%);
		background: linear-gradient(to right,  rgba(0,0,0,0) 10%,rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
	}
}

@include respond-max(800px) {
	.donate-box__content {
		left: 42%;
		width: 52%;
	}
}

@include respond-min(640px) {
	.donate-box__overlay {
		opacity: 1;
		background: -moz-linear-gradient(left,  rgba(0,0,0,0) 20%, rgba(0,0,0,1) 100%);
		background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 20%,rgba(0,0,0,1) 100%);
		background: linear-gradient(to right,  rgba(0,0,0,0) 20%,rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
	}
}

@include respond-max(639px) {
	.donate-box__content {
		width: 100%;
		left: auto;
		padding: 0 20px;
		text-align: center;
		font-size: 16px;
		line-height: 1.3em;
	}
	.donate-box__cta {
		margin-bottom: 15px;
	}
}