.site-footer__wrapper {
	background-color: #e8e4d8;
	padding-top: 35px;
}

.site-footer {
	@include font-size(1.4, 1.28);
	max-width: 1028px;
	padding-left: 14px;
	padding-right: 14px;
	margin: 0 auto;
	color: #464646;
	h2 {
		@include font-size(1.4, 1.1);
		text-transform: lowercase;
		color: #464646;;
		a {
			color: inherit;
		}
	}
	a.icon-link {
		color: $orange;
		transition: color 0.15s;
		&:hover {
			color: $orange-hover;
			text-decoration: none;
			.text {
				text-decoration: underline;
			}
		}
		.text {
			color: #464646;
			margin-left: 0.5em;
		}
	}
	&__directions {
		margin-top: 0.5em;
	}
	.directions-link {
		@include font-size(1.4, 1.28);
	}
	.subscribe-link {
		display: table;
		width: auto;
		margin: 0 auto;
		&__row {
			display: table-row;
		}
		&__col {
			display: table-cell;
			vertical-align: middle;
			@include font-size(1.4, 1.3);
			&:first-child {
				width: 30px;
			}
		}
		.text {
			display: block;
			padding-left: 5px;
		}
	}
	.social-buttons {
		display: block;
		margin: 0.75em 0 1em;
		list-style-type: none;
		padding: 0;
		a {
			color: #7b746b;
			&:hover {
				color: #464646;
			}
		}
		> li {
			display: inline-block;
			margin: 0 0 0.85em 0;
			padding: 0;
			&:not(:last-child) {
				margin-right: 0.75em;
			}
		}
	}
}

.site-footer__address {
	line-height: 1.3;
	.org {
		line-height: 1.3;
		display: block;
		margin-bottom: 0.25em;
	}
}

.site-footer__columns {
	@include clearfix;
}

.org-info {
	.fn {
		@include avenir-bold;
	}
	a {
		color: inherit;
	}
}

.org-links {
	//@include retina-image("../img/checkered-border", 2px 2px);
	//background-repeat: repeat-x;
	padding-top: 12px;
	margin-top: 12px;
}

.org-link,
.org-links__set a {
	@include font-size(2.9, 1.1);
	@include inline-block;
	color: #c78a3c;
	padding: 5px;
	@include respond-min(768px) {
		@include font-size(1.8, 1.1);
		padding: 0 3px 0;
		margin: 7px 0 9px;
	}
}

.org-links__set {
	ul {
		@include font-size(0, 0);
		margin: 0;
		padding: 0;
		list-style: none;
	}
	li {
		@include inline-block;
	}
	h2 {
		margin-top: .5em;
	}
	& > a,
	ul {
		margin-left: -5px;
	}
}

.site-footer__column {
	padding: 0 0 25px;
	&:first-child {
		padding-top: 25px;
	}
	@include respond-min(768px) {
		float: left;
		width: 32%;
		padding: 25px 0 25px;
		& + .site-footer__column {
			padding: 25px 0 25px 2%;
			margin: 0 0 0 2%;
		}
	}
}

.donate-cta-wrapper {
	position: relative;
}
.donate-cta {
	display: block;
	background: $orange;
	color: #fff;
	text-align: center;
	height: 50px;
	padding: 0 1em;
	@include font-size(1.6, 50px);
	transition: background 0.15s;
	border-radius: 5px;
	@include avenir-bold;
	position: absolute;
	width: 240px;
	left: 50%;
	top: -25px;
	margin-left: -120px;
	border: 1px solid #b94701;
	.text {
		margin-left: 0.4em;
	}
	&:hover {
		text-decoration: none !important;
		// background: $orange-hover;
		background: #fb953b;
	}
	@media (min-width: 768px) {
		width: 350px;
		margin-left: -175px;
	}
}

.site-footer__donate-button {
	margin-bottom: 20px;
	text-align: center;
	@include ct-button(#fff, $orange, $orange, 14px);
	display: block;
	width: 100%;
	.text {
		margin-left: 0.4em;
	}
}

.footer-nav {
	ul {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 1.25em;
		&:first-child {
			margin-right: 45px;
		}
		li {
			display: block;
			&:not(:last-child) {
				margin-bottom: 1.25em;
			}
		}
		a {
			@include font-size(1.4, 1.3);
			@include avenir-bold;
			color: #464646;
			&:hover {
				text-decoration: underline !important;
			}
		}
	}
}

.site-footer__copyright-row {
	@include clearfix;
	@include font-size(1.2, 1.3);
	padding-bottom: 20px;
	ul {
		margin: 3px 0 0;
		padding: 0;
		list-style: none;
		@include respond-min(480px) {
			display: inline;
			margin-left: 29px;
		}
	}
	li {
		display: inline;
		& + li {
			margin-left: 25px;
		}
	}
	a {
		color: inherit;
		// border-bottom: 2px solid #c5c3b9;
	}
}

.site-footer__logos,
.site-footer__copyright-text {
	display: inline-block;
	height: 65px;
	position: relative;
}
@media (max-width: 767px) {
	.site-footer {
		text-align: center;
	}
}
@media (max-width: 899px) {
	.site-footer__logos,
	.site-footer__copyright-text {
		display: block;
		width: 100%;
		height: auto;
		@include clearfix;
		text-align: center;
	}
	.site-footer__logos {
		margin-bottom: 20px;
	}
}
@media (min-width: 900px) {
	.site-footer__copyright-text__inner {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		white-space: nowrap;
	}
	.site-footer__logos {
		float: right;
	}
}

.site-footer__logos {
	a {
		border-bottom: 0 !important;
		&:not(:last-child) {
			img {
				margin-right: 10px;
			}
		}
	}
}