.statslider {
	@include mobile-padding(30px, 30px);
	background: #fff;
	margin-left: 20px;
	margin-right: 20px;
	> h1 {
		@include font-size(1.8, 1.333);
		margin-bottom: 22px;
	}
	ul {
		padding: 0;
	}
	.slides > li {
		margin-right: 27px;
		& + li {
			margin-left: 27px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.statslider__slide__figure,
.statslider__slide__text {
	border-top: 1px solid #f9e6aa;
}

.statslider__slide__figure {
	color: #f2c459;
	padding: 24px 0;
	text-align: center;
	letter-spacing: .1em;
	b, strong {
		letter-spacing: -.08em;
	}
	span {
		display: block;
	}
}

.statslider__slide__text {
	@include clarendon-regular;
	@include font-size(1.3, 1.3);
	color: $soft-brown;
}

.statslider__slide__link {
	@include font-size(1.4, 1.43);
	a {
		color: $brand-color;
	}
}