.desktop-nav__wrapper {
	@include brown-paper-bg;
		height:36px;
	@include respond-min(600px) {
		display:block;
	}
}

.desktop-nav {
	max-width:1000px;
	margin:0 auto;
	text-transform:lowercase;
	ul {
		background: url("../img/desktop-nav-border.png") no-repeat top right;
		font-size:0;
		line-height:0;
		height:36px;
		position:relative;

		ul {
			display:none;
			position:absolute;
			z-index:11;
			background:#f5f5f5;
			width:30%;
			border:1px solid #d5d2c7;
			border-radius:4px;
			height:auto;
			&:before {
				content:"";
				position:relative;
				top:-5px;
				left:16%;
				width: 0px;
				height: 0px;
				border-style: solid;
				border-width: 0 7px 5px 7px;
				border-color: transparent transparent #f5f5f5 transparent;
			}

			li {
				@include font-size(1.4, 1.14);
				display:block;
				background:#f5f5f5;
				&:hover,
				&.here,
				&.parent-here {background:#fff;}
				a {
					@include avenir-regular;
					color:#444;
					text-shadow:none;
					text-align: left;
					padding:.6667em 2em;
					background:none;
				}
				& + li {
					border-top:1px dashed #d5d2c7;
				}
			}
		}
	}

	> ul > li {
		@include inline-block;
		@include font-size(1.5, 1.1);
		background:rgba(255,255,255,.1);
		&.last ul {
			right:0px;
			&:before {
				left:77%;
			}
		}
		&:hover {
			background:rgba(255,255,255,.2);
			ul {display:block;}
		}
		&.here:after,
		&.parent-here:after {
			content: "";
			display:block;
			width:100%;
			height:4px;
			background:#ffcc40;
			top:0;
			left:0;
			position:relative;
			z-index:10;
		}
	}

	a {
		@include avenir-bold;
		display:block;
		color:#fff;
		background: url("../img/desktop-nav-border.png") no-repeat;
		overflow:hidden;
		padding:.6667em 0;
		text-shadow: 0 1px 7px rgba(0,0,0,.4);
		text-align: center;
	}
}
.desktop-nav > ul {
	display:table;
	width:100%;
}

.desktop-nav > ul > li {
	display:table-cell;
}
