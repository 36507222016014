.homepage-hero {
	a {text-decoration:none;}
}
.homepage-hero__orange-text {
	@include clarendon-bold-italic;
	@include font-size(1.8, 1.3);

	color: #e7c45a;
	text-shadow:2px 0 2px rgba(0,0,0,.55);
}

.homepage-hero__small-text,
.homepage-hero__large-text {
	@include avenir-bold;
	display:block;
	color: #fff;
	text-shadow:2px 0 2px rgba(0,0,0,.55);
}

.homepage-hero__large-text {@include font-size(3.1, 1.0);}
.homepage-hero__small-text {
	@include font-size(1.1, 1.41);
	margin-top:4px;
	letter-spacing:.05em;
}

.homepage-hero__content {
	-webkit-font-smoothing:antialiased;
}