body.news-events {
	.inline-filter-options {
		float: none;
	}
	.page-list-container {
		margin-top: 0;
		box-shadow: none;
		// border-top: 1px solid #f2c459;
		@include respond-min(600px) {
			margin-top: 2em;
			box-shadow: 0 0 34px rgba(0, 0, 0, .2);
			border-top: none;
		}
	}
	.interior--three-column {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.news-events {
	.inline-filter-options {
		span {
			padding-left: 0;
		}
	}
}

right-column--three-column,
.right-column--three-column h2 {
	@include clarendon-regular;
}

.right-column--three-column {
	@include font-size(1.2, 1.3);
	color: #7b746b;
	h2 {
		@include font-size(1.3, 1.3);
	}
}

.news-event-detail__byline {
	@include font-size(1.3, 1.2);
	@include clarendon-italic;
	text-transform: lowercase;
	color: #a69b8f;
	margin-bottom: 10px;
	.bull {
		margin: 0 2px;
	}
}

.news-event-detail__title {
	h1 {
		@include font-size(2.4, 1.25)
	}
;
	text-transform: lowercase;
}

.media-center__list-item__type,
.media-center__list-item__title {
	text-transform: lowercase;
	.bull {
		margin: 0 2px;
	}
}

.media-center__list-item__teaser {
	margin-top: 4px;
	@include font-size(1.4, 1.375);
}

.inline-sharing-controls {
	display: block;
	margin: 10px 0 15px;
	height: 24px;
	&__label {
		display: inline-block;
		color: #8c8379;
		font-size: 12px;
		font-size: 1.2rem;
		line-height: 24px;
		margin: 1px 4px 0 0;
	}
	&__control {
		display: inline-block;
		a {
			display: inline-block;
			font-size: 24px;
			font-size: 2.4rem;
			line-height: 24px;
			color: #7b746b;
			margin: 0 3px;
			vertical-align: top;
		}
	}
}