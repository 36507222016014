.staff.h2 {
	font-size: 2em;
	margin-top: 0;
	margin-bottom: 16px;
}

.person-container {
    display: flex;
    flex-wrap: wrap;
}

.person-container .person {
    width: 100%;
    padding: 16px;
    text-align: center;
    cursor: pointer;
}

.person-container .person:focus {
	outline: dotted 1px #e46f07;
}

.person-container .person .img {
    display: block;
    width: 100%;
}

.person-container .person .h3 {
    margin-top: 8px;
    
}

.person-container .person .p {
    font-size: 16px;
    margin: 4px 0 0 0;
    padding: 0;
}

.person-container .person .p.email {
    font-size: 14px;
    word-break: break-word;
}

.person-container .person-bio {
    display: none;
    padding: 16px;
}

.person-container .person-bio .person-bio-wrapper {
	padding: 16px;
	background: white;
	position: relative;
}

.person-container .person-bio .header {
	margin-bottom: 32px;
	padding-right: 36px;
}

.person-container .person-bio .h4 {
	font-size: 32px;
	display: inline-block;
	position: relative;
}

.person-container .person-bio .h4:after {
	content: "";
	display: block;
	width: 50%;
	height: 3px;
	position: absolute;
	bottom: -16px;
	background: white;
}

.person-container .person-bio .button.close {
	position: absolute;
	right: 16px;
	top: 16px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	background: none;
	padding: 0;
	margin: 0;
	border: 0;
	background: white;
}

.person-container .person-bio .button.close:after {
	content: "X";
	color: #e46f07;
	font-size: 24px;
	display: block;
	font-family: 'Avenir Next LT W01 Bold',helvetica,arial,sans-serif;
}


.list-container {
	margin-top: 48px;
}

.list-container .person-list {
	margin-top: 24px;
	margin-bottom: 24px;
}

.list-container .person-list h3 {
	margin-bottom: 12px;
	color: #444;
}

.list-container .person-list .p {
	color: #444;
	margin: 0;
}

@media only screen and (min-width: 544px) {
	.person-container .person {
	    width: 50%;
	}
}
@media only screen and (min-width: 768px) {
	.person-container .person {
	    width: 33%;
	}
}
@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

}