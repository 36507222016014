@charset "UTF-8";
/**
 * Correct `block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * Address `font-family` inconsistency between `textarea` and other form
 * elements.
 */
html,
button,
input,
select,
textarea {
  font-family: 'AvenirNextLTW01-Regular', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important; }

/**
 * Address margins handled incorrectly in IE 6/7.
 */
body {
  margin: 0; }

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Address font sizes and margins set differently in IE 6/7.
 * Address font sizes within `section` and `article` in Firefox 4+, Safari 5,
 * and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 1em;
  margin: 1.67em 0; }

h6 {
  font-size: 1em;
  margin: 2.33em 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  margin: 0;
  line-height: 1.1;
  font-weight: normal;
  color: #e46f07; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #e46f07; }

/**
 * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
 */
blockquote {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 * Known issue: no IE 6/7 normalization.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* ==========================================================================
   Lists
   ========================================================================== */
/**
 * Address margins set differently in IE 6/7.
 */
dl,
menu,
ol,
ul {
  margin: 1em 0; }

/**
 * Address paddings set differently in IE 6/7.
 */
menu,
ol,
ul {
  padding: 0 0 0 40px; }

/**
 * Correct list images handled incorrectly in IE 7.
 */
nav ul,
nav ol {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none; }

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }
  main.interior img {
    max-width: 100%;
    height: auto !important; }

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* ==========================================================================
   Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
/*
 * Hide visually and from screenreaders, but maintain layout
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html,
button,
input,
select,
textarea {
  color: #444; }

html {
  font-size: 62.5%;
  line-height: 1.4;
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  font-family: 'AvenirNextLTW01-Regular', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  background-color: #f6f5f4;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.3;
  min-width: 280px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.container {
  overflow: hidden; }

.cke_editable {
  padding: 1em; }

main {
  max-width: 1000px;
  margin: 0 auto;
  overflow: visible; }

a, .a {
  color: #0071ae;
  text-decoration: none; }
  a:hover, .a:hover {
    text-decoration: underline; }
  nav a:hover, nav .a:hover {
    text-decoration: none !important; }

[class*="button"] a:hover {
  text-decoration: none !important; }

b,
strong {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important; }

i,
em {
  font-family: 'AvenirNextLTW01-Italic', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important; }

i b,
i strong,
em b,
em strong,
b i,
b em,
strong i,
strong em {
  font-family: 'AvenirNextLTW01-BoldIta', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important; }

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */
audio,
canvas,
img,
video {
  vertical-align: middle; }

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical; }

/* ==========================================================================
   Browse Happy prompt
   ========================================================================== */
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

abbr {
  text-decoration: none !important; }

.ct-button-inline {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  background: #e46e05;
  font-size: 80%;
  border-radius: 5px;
  border: 1px solid #b94701;
  text-align: center;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  padding: .125em 0.5em;
  text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: .04em;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }
  .ct-button-inline:hover {
    background: #fb953b; }

/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: "AvenirNextLTW01-Regular";
  src: url("/_ui/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src: url("/_ui/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"), url("/_ui/fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"), url("/_ui/fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype"), url("/_ui/fonts/52a192b1-bea5-4b48-879f-107f009b666f.svg#52a192b1-bea5-4b48-879f-107f009b666f") format("svg"); }

@font-face {
  font-family: "AvenirNextLTW01-Italic";
  src: url("/_ui/fonts/d1fddef1-d940-4904-8f6c-17e809462301.eot?#iefix");
  src: url("/_ui/fonts/d1fddef1-d940-4904-8f6c-17e809462301.eot?#iefix") format("eot"), url("/_ui/fonts/92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff") format("woff"), url("/_ui/fonts/18839597-afa8-4f0b-9abb-4a30262d0da8.ttf") format("truetype"), url("/_ui/fonts/1de7e6f4-9d4d-47e7-ab23-7d5cf10ab585.svg#1de7e6f4-9d4d-47e7-ab23-7d5cf10ab585") format("svg"); }

@font-face {
  font-family: "Avenir Next LT W01 Bold";
  src: url("/_ui/fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix");
  src: url("/_ui/fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix") format("eot"), url("/_ui/fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"), url("/_ui/fonts/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype"), url("/_ui/fonts/ed104d8c-7f39-4e8b-90a9-4076be06b857.svg#ed104d8c-7f39-4e8b-90a9-4076be06b857") format("svg"); }

@font-face {
  font-family: "AvenirNextLTW01-BoldIta";
  src: url("/_ui/fonts/ac2d4349-4327-448f-9887-083a6a227a52.eot?#iefix");
  src: url("/_ui/fonts/ac2d4349-4327-448f-9887-083a6a227a52.eot?#iefix") format("eot"), url("/_ui/fonts/25e83bf5-47e3-4da7-98b1-755efffb0089.woff") format("woff"), url("/_ui/fonts/4112ec87-6ded-438b-83cf-aaff98f7e987.ttf") format("truetype"), url("/_ui/fonts/ab1835cb-df6f-4d8d-b8ee-3075f5ba758d.svg#ab1835cb-df6f-4d8d-b8ee-3075f5ba758d") format("svg"); }

@font-face {
  font-family: "ClarendonTextW01-Italic 1097339";
  src: url("/_ui/fonts/e6ace793-83b5-4b0d-b428-73ce5f94ef5c.eot?#iefix");
  src: url("/_ui/fonts/e6ace793-83b5-4b0d-b428-73ce5f94ef5c.eot?#iefix") format("eot"), url("/_ui/fonts/2f981756-5524-486f-a551-e0b49f233057.woff") format("woff"), url("/_ui/fonts/c2e864f5-9835-4768-a9da-b39996a8a7c0.ttf") format("truetype"), url("/_ui/fonts/b15e50b7-94f4-40bd-bbf9-2bc537acf69c.svg#b15e50b7-94f4-40bd-bbf9-2bc537acf69c") format("svg"); }

@font-face {
  font-family: "ClarendonTextW01-BoldIt";
  src: url("/_ui/fonts/b57b68c4-caa9-4710-b336-da36af4a59ba.eot?#iefix");
  src: url("/_ui/fonts/b57b68c4-caa9-4710-b336-da36af4a59ba.eot?#iefix") format("eot"), url("/_ui/fonts/c1041698-b342-494f-85b7-a1221ca4ac50.woff") format("woff"), url("/_ui/fonts/85ae3014-0fed-444a-9dde-1f4bd46131bc.ttf") format("truetype"), url("/_ui/fonts/d578bf2e-32b3-4fc9-b27f-34f11b61d74d.svg#d578bf2e-32b3-4fc9-b27f-34f11b61d74d") format("svg"); }

@font-face {
  font-family: "Clarendon Text W01 Bold";
  src: url("/_ui/fonts/48333468-09e7-4f55-a78a-19054b68343c.eot?#iefix");
  src: url("/_ui/fonts/48333468-09e7-4f55-a78a-19054b68343c.eot?#iefix") format("eot"), url("/_ui/fonts/e84c14f1-c4b2-4c55-8688-534776ef764b.woff") format("woff"), url("/_ui/fonts/b503c9b2-f69e-4e11-b1aa-25857c5a6ac9.ttf") format("truetype"), url("/_ui/fonts/05ff0a7c-f1d1-4663-91b8-26ca792e35ef.svg#05ff0a7c-f1d1-4663-91b8-26ca792e35ef") format("svg"); }

@font-face {
  font-family: "ClarendonTextW01-Regula 1097896";
  src: url("/_ui/fonts/8a342978-8cc3-4a55-ae38-9fb02f110f05.eot?#iefix");
  src: url("/_ui/fonts/8a342978-8cc3-4a55-ae38-9fb02f110f05.eot?#iefix") format("eot"), url("/_ui/fonts/35604d37-6874-4349-b584-e0ddd377401c.woff") format("woff"), url("/_ui/fonts/33e7055e-9053-4cea-aa8c-110ec604a56d.ttf") format("truetype"), url("/_ui/fonts/c657a5dd-2aac-42cc-964a-c9e1c999e118.svg#c657a5dd-2aac-42cc-964a-c9e1c999e118") format("svg"); }

.fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-fw {
  width: 1.2857142857142858em;
  text-align: center; }

.fa-ul {
  padding-left: 0;
  margin-left: 2.142857142857143em;
  list-style-type: none; }

.fa-ul > li {
  position: relative; }

.fa-li {
  position: absolute;
  left: -2.142857142857143em;
  width: 2.142857142857143em;
  top: 0.14285714285714285em;
  text-align: center; }

.fa-li.fa-lg {
  left: -1.8571428571428572em; }

.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

.fa.pull-left {
  margin-right: .3em; }

.fa.pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear; }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(359deg); } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1); }

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  width: 100%;
  text-align: center; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #ffffff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000"; }

.fa-music:before {
  content: "\f001"; }

.fa-search:before {
  content: "\f002"; }

.fa-envelope-o:before {
  content: "\f003"; }

.fa-heart:before {
  content: "\f004"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-o:before {
  content: "\f006"; }

.fa-user:before {
  content: "\f007"; }

.fa-film:before {
  content: "\f008"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-check:before {
  content: "\f00c"; }

.fa-times:before {
  content: "\f00d"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-signal:before {
  content: "\f012"; }

.fa-gear:before,
.fa-cog:before {
  content: "\f013"; }

.fa-trash-o:before {
  content: "\f014"; }

.fa-home:before {
  content: "\f015"; }

.fa-file-o:before {
  content: "\f016"; }

.fa-clock-o:before {
  content: "\f017"; }

.fa-road:before {
  content: "\f018"; }

.fa-download:before {
  content: "\f019"; }

.fa-arrow-circle-o-down:before {
  content: "\f01a"; }

.fa-arrow-circle-o-up:before {
  content: "\f01b"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-play-circle-o:before {
  content: "\f01d"; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e"; }

.fa-refresh:before {
  content: "\f021"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-lock:before {
  content: "\f023"; }

.fa-flag:before {
  content: "\f024"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-print:before {
  content: "\f02f"; }

.fa-camera:before {
  content: "\f030"; }

.fa-font:before {
  content: "\f031"; }

.fa-bold:before {
  content: "\f032"; }

.fa-italic:before {
  content: "\f033"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-list:before {
  content: "\f03a"; }

.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-video-camera:before {
  content: "\f03d"; }

.fa-picture-o:before {
  content: "\f03e"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-tint:before {
  content: "\f043"; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044"; }

.fa-share-square-o:before {
  content: "\f045"; }

.fa-check-square-o:before {
  content: "\f046"; }

.fa-move:before {
  content: "\f047"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-play:before {
  content: "\f04b"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-eject:before {
  content: "\f052"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-times-circle-o:before {
  content: "\f05c"; }

.fa-check-circle-o:before {
  content: "\f05d"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-mail-forward:before,
.fa-share:before {
  content: "\f064"; }

.fa-resize-full:before {
  content: "\f065"; }

.fa-resize-small:before {
  content: "\f066"; }

.fa-plus:before {
  content: "\f067"; }

.fa-minus:before {
  content: "\f068"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-plane:before {
  content: "\f072"; }

.fa-calendar:before {
  content: "\f073"; }

.fa-random:before {
  content: "\f074"; }

.fa-comment:before {
  content: "\f075"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-resize-vertical:before {
  content: "\f07d"; }

.fa-resize-horizontal:before {
  content: "\f07e"; }

.fa-bar-chart-o:before {
  content: "\f080"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-key:before {
  content: "\f084"; }

.fa-gears:before,
.fa-cogs:before {
  content: "\f085"; }

.fa-comments:before {
  content: "\f086"; }

.fa-thumbs-o-up:before {
  content: "\f087"; }

.fa-thumbs-o-down:before {
  content: "\f088"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-heart-o:before {
  content: "\f08a"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-linkedin-square:before {
  content: "\f08c"; }

.fa-thumb-tack:before {
  content: "\f08d"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-upload:before {
  content: "\f093"; }

.fa-lemon-o:before {
  content: "\f094"; }

.fa-phone:before {
  content: "\f095"; }

.fa-square-o:before {
  content: "\f096"; }

.fa-bookmark-o:before {
  content: "\f097"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-github:before {
  content: "\f09b"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-hand-o-right:before {
  content: "\f0a4"; }

.fa-hand-o-left:before {
  content: "\f0a5"; }

.fa-hand-o-up:before {
  content: "\f0a6"; }

.fa-hand-o-down:before {
  content: "\f0a7"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-fullscreen:before {
  content: "\f0b2"; }

.fa-group:before {
  content: "\f0c0"; }

.fa-chain:before,
.fa-link:before {
  content: "\f0c1"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4"; }

.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-reorder:before {
  content: "\f0c9"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-plus:before {
  content: "\f0d5"; }

.fa-money:before {
  content: "\f0d6"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-down:before,
.fa-sort-asc:before {
  content: "\f0dd"; }

.fa-sort-up:before,
.fa-sort-desc:before {
  content: "\f0de"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-linkedin:before {
  content: "\f0e1"; }

.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2"; }

.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3"; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4"; }

.fa-comment-o:before {
  content: "\f0e5"; }

.fa-comments-o:before {
  content: "\f0e6"; }

.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea"; }

.fa-lightbulb-o:before {
  content: "\f0eb"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-bell-o:before {
  content: "\f0a2"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-cutlery:before {
  content: "\f0f5"; }

.fa-file-text-o:before {
  content: "\f0f6"; }

.fa-building:before {
  content: "\f0f7"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b"; }

.fa-circle-o:before {
  content: "\f10c"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-circle:before {
  content: "\f111"; }

.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-folder-o:before {
  content: "\f114"; }

.fa-folder-open-o:before {
  content: "\f115"; }

.fa-expand-o:before {
  content: "\f116"; }

.fa-collapse-o:before {
  content: "\f117"; }

.fa-smile-o:before {
  content: "\f118"; }

.fa-frown-o:before {
  content: "\f119"; }

.fa-meh-o:before {
  content: "\f11a"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-keyboard-o:before {
  content: "\f11c"; }

.fa-flag-o:before {
  content: "\f11d"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-code:before {
  content: "\f121"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-mail-reply-all:before {
  content: "\f122"; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-crop:before {
  content: "\f125"; }

.fa-code-fork:before {
  content: "\f126"; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127"; }

.fa-question:before {
  content: "\f128"; }

.fa-info:before {
  content: "\f129"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-shield:before {
  content: "\f132"; }

.fa-calendar-o:before {
  content: "\f133"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-unlock-o:before {
  content: "\f13e"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-ellipsis-horizontal:before {
  content: "\f141"; }

.fa-ellipsis-vertical:before {
  content: "\f142"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-minus-square-o:before {
  content: "\f147"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-pencil-square:before {
  content: "\f14b"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150"; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151"; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152"; }

.fa-euro:before,
.fa-eur:before {
  content: "\f153"; }

.fa-gbp:before {
  content: "\f154"; }

.fa-dollar:before,
.fa-usd:before {
  content: "\f155"; }

.fa-rupee:before,
.fa-inr:before {
  content: "\f156"; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157"; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158"; }

.fa-won:before,
.fa-krw:before {
  content: "\f159"; }

.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-text:before {
  content: "\f15c"; }

.fa-sort-alpha-asc:before {
  content: "\f15d"; }

.fa-sort-alpha-desc:before {
  content: "\f15e"; }

.fa-sort-amount-asc:before {
  content: "\f160"; }

.fa-sort-amount-desc:before {
  content: "\f161"; }

.fa-sort-numeric-asc:before {
  content: "\f162"; }

.fa-sort-numeric-desc:before {
  content: "\f163"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-youtube-square:before {
  content: "\f166"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-youtube-play:before {
  content: "\f16a"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-adn:before {
  content: "\f170"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitbucket-square:before {
  content: "\f172"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-apple:before {
  content: "\f179"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-android:before {
  content: "\f17b"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-trello:before {
  content: "\f181"; }

.fa-female:before {
  content: "\f182"; }

.fa-male:before {
  content: "\f183"; }

.fa-gittip:before {
  content: "\f184"; }

.fa-sun-o:before {
  content: "\f185"; }

.fa-moon-o:before {
  content: "\f186"; }

.fa-archive:before {
  content: "\f187"; }

.fa-bug:before {
  content: "\f188"; }

.fa-vk:before {
  content: "\f189"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-arrow-circle-o-right:before {
  content: "\f18e"; }

.fa-arrow-circle-o-left:before {
  content: "\f190"; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191"; }

.fa-dot-circle-o:before {
  content: "\f192"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195"; }

.mobile-nav {
  background-color: #cb8b3b;
  padding: 1em 14px 1em;
  display: none;
  text-transform: lowercase;
  box-shadow: inset 0px -2px 12px 5px rgba(0, 0, 0, 0.33);
  color: #fff;
  -webkit-font-smoothing: antialiased; }
  .mobile-nav a {
    color: inherit; }
  .mobile-nav .content {
    *zoom: 1;
    opacity: 0;
    position: relative;
    top: -50px; }
    .mobile-nav .content:before, .mobile-nav .content:after {
      content: " ";
      display: table; }
    .mobile-nav .content:after {
      clear: both; }

.mobile-nav__main-nav {
  *zoom: 1;
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important; }
  .mobile-nav__main-nav:before, .mobile-nav__main-nav:after {
    content: " ";
    display: table; }
  .mobile-nav__main-nav:after {
    clear: both; }
  .mobile-nav__main-nav li {
    float: left;
    width: 50%;
    width: 31%;
    padding: 0 1%;
    width: auto;
    padding: 0; }
    .mobile-nav__main-nav li + li {
      margin-left: 1.5em; }

.mobile-nav__utility-nav {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.3;
  text-align: center;
  width: 50%;
  float: right; }
  .mobile-nav__utility-nav li {
    display: inline; }
  .mobile-nav__utility-nav a {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    padding: 1em .375em 0; }

.mobile-nav__site-search {
  margin: 12px 0 0;
  font-size: 0;
  line-height: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
  width: 50%;
  float: left; }
  .mobile-nav__site-search input {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.1;
    width: 85%;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: #fff; }
    .mobile-nav__site-search input::-webkit-input-placeholder {
      color: #ccc; }
    .mobile-nav__site-search input:-moz-placeholder {
      color: #ccc; }
    .mobile-nav__site-search input::-moz-placeholder {
      color: #ccc; }
    .mobile-nav__site-search input:-ms-input-placeholder {
      color: #ccc; }
  .mobile-nav__site-search button {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.1;
    width: 15%;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: #fff;
    position: relative; }

.mobile-interior-title {
  background: #fff;
  border-top: 2px solid #faeadc;
  border-bottom: 1px solid #e8e4d8;
  padding: 12px 14px 12px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  display: none; }
  .mobile-interior-title h1 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.1;
    color: #e46f07; }
  .mobile-interior-title .mobile-interior-title__bottom-shadow {
    width: 100%;
    height: 29px;
    position: absolute;
    bottom: 0;
    left: 0; }

.site-header__wrapper {
  background: #fff;
  min-height: 115px; }

.site-header {
  *zoom: 1;
  padding: 5px 12px 5px 9px;
  margin: 0 auto;
  width: 1000px;
  padding: 0 0 14px; }
  .site-header:before, .site-header:after {
    content: " ";
    display: table; }
  .site-header:after {
    clear: both; }
  @media print {
    .site-header {
      width: 100% !important; } }

.site-header__logo {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  width: 145px;
  height: 57px;
  background-image: url("../img/childrenstrust-logo.png");
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  float: left;
  margin: 0;
  padding: 0;
  width: 216px;
  height: 86px;
  background-image: url("../img/childrenstrust-logo-lg.png");
  margin-top: 9px;
  position: relative;
  left: -35px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
    .site-header__logo {
      background-image: url("../img/childrenstrust-logo@2x.png");
      background-size: 145px 57px; } }
  .site-header__logo a {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    width: 175px;
    height: 69px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
    .site-header__logo {
      background-image: url("../img/childrenstrust-logo-lg@2x.png");
      background-size: 216px 86px; } }
  .site-header__logo a {
    width: 216px;
    height: 86px; }
  @media print {
    .site-header__logo {
      left: 0 !important; } }

.site-header__show-mobile-nav {
  width: 46px;
  height: 40px;
  background-image: url("../img/mobile-menu-button.png");
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
  float: right;
  border: none;
  padding: 10px;
  margin: 33px 0 0 0;
  position: relative;
  top: -10px;
  right: -10px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-origin: content-box;
  display: none; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
    .site-header__show-mobile-nav {
      background-image: url("../img/mobile-menu-button@2x.png");
      background-size: 26px 20px; } }

.site-header__site-search,
.site-header__util-and-social,
.desktop-nav__wrapper {
  display: none; }

@media (min-width: 600px) and (max-width: 969px) {
  .site-header {
    padding-bottom: 24px; } }

.site-header__site-search {
  font-size: 0px;
  font-size: 0rem;
  line-height: 0;
  display: block;
  float: right;
  border-bottom: 1px solid #d5d2c7;
  margin-top: 9px; }
  .site-header__site-search input, .site-header__site-search button {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.1;
    background: none;
    border: none;
    padding: 0;
    margin: 0; }
  .site-header__site-search button {
    color: #7b746b; }

.site-header__util-and-social {
  display: block;
  float: right;
  clear: right; }

.site-header__utility-nav {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.1;
  float: right;
  clear: right;
  margin-top: 1em; }
  .site-header__utility-nav li {
    float: left; }
    .site-header__utility-nav li + li {
      margin-left: 1.35em; }
  .site-header__utility-nav a {
    color: #7b746b; }
    .site-header__utility-nav a:hover .text {
      text-decoration: underline !important; }
  .site-header__utility-nav li.donate a {
    display: inline-block;
    background-color: #e46f07;
    border: 0;
    color: #fff;
    padding: 7px 12px 7px 12px;
    margin-top: -11px;
    border-radius: 5px;
    transition: color 0.15s, background 0.15s;
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important; }
    .site-header__utility-nav li.donate a:hover {
      color: #fff;
      background: #b94408; }
      .site-header__utility-nav li.donate a:hover .text {
        text-decoration: none !important; }

.site-header__social-nav {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1;
  margin-top: 18px;
  float: right;
  clear: right; }
  .site-header__social-nav li {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto; }
    .site-header__social-nav li:not(:last-child) {
      margin-right: 10px; }
  .site-header__social-nav a {
    color: #7b746b;
    transition: color 0.15s; }
    .site-header__social-nav a:hover {
      color: #464646; }
  .site-header__social-nav .header-envelope {
    font-size: 24px;
    width: 24px;
    position: relative;
    top: 4px; }

.site-header__utility-nav,
.site-header__social-nav {
  float: left; }

.site-header__utility-nav {
  margin-top: 21px; }

.site-header__social-nav {
  margin-top: 18px;
  margin-left: 18px; }

.site-header__site-search {
  margin-top: 30px; }

.news-stories-footer__wrapper {
  padding-top: 32px;
  padding-bottom: 49px;
  background-color: #dbd5c2;
  position: relative;
  overflow: hidden;
  padding: 0 0 40px 0; }

.news-stories-footer {
  *zoom: 1;
  max-width: 1028px;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0 auto;
  padding-bottom: 23px; }
  .news-stories-footer:before, .news-stories-footer:after {
    content: " ";
    display: table; }
  .news-stories-footer:after {
    clear: both; }
  .news-stories-footer h2 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.1;
    color: #464646;
    margin-bottom: 12px;
    -webkit-font-smoothing: antialiased; }

.news-stories-footer .ul {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  list-style: none;
  position: relative;
  height: 400px;
  margin-top: 32px;
  box-sizing: border-box; }

.news-stories-footer .ul .li {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 16px;
  box-sizing: border-box;
  cursor: pointer; }

.news-stories-footer .ul .li:focus {
  outline: dotted 2px #0071ae; }

.news-stories-footer .ul .li .p {
  color: white; }

.news-stories-footer .ul .li .img {
  display: block;
  width: calc(100% - 32px);
  z-index: 1;
  position: absolute;
  left: 16px;
  top: 0;
  box-sizing: border-box; }

.news-stories-footer .ul .li .text-content {
  position: relative;
  background: #e46f07;
  z-index: 2;
  padding: 0 16px 16px 16px; }

.news-stories-footer .ul .li .p.headline {
  position: relative;
  text-align: center;
  text-transform: capitalize; }

.news-stories-footer .ul .li .p.teaser {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px; }

.news-stories-footer .ul .li .headline-text {
  position: relative;
  z-index: 2;
  display: block;
  height: 112px;
  overflow: hidden; }

.news-stories-footer .ul .li .p.teaser:before {
  content: "";
  display: block;
  position: absolute;
  top: -35px;
  left: 50%;
  height: 73px;
  width: calc(100% + 32px);
  background: #e46f07;
  border-radius: 50%;
  z-index: 2;
  -webkit-transform: skew(0) rotate(0) translateX(-50%);
  -moz-transform: skew(0) rotate(0) translateX(-50%);
  -o-transform: skew(0) rotate(0) translateX(-50%);
  -ms-transform: skew(0) rotate(0) translateX(-50%);
  transform: skew(0) rotate(0) translateX(-50%); }

.news-stories-footer .ul .li .p.teaser:after {
  content: "";
  display: block;
  width: 100%;
  height: 32px;
  position: absolute;
  bottom: 0;
  z-index: 3;
  background: -moz-linear-gradient(top, rgba(228, 111, 7, 0) 0%, #e46f07 100%);
  background: -webkit-linear-gradient(top, rgba(228, 111, 7, 0) 0%, #e46f07 100%);
  background: linear, to bottom, rgba(228, 111, 7, 0) 0%, #e46f07 100%; }

.news-stories-footer .ul .li .p.teaser-prompt {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  margin-top: 8px;
  font-style: italic;
  font-size: 18px; }

.news-stories-footer .ul .li .p.teaser-prompt:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  margin-right: 8px;
  background-image: url(../img/news-teaser-prompt.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; }

.news-stories-footer .ul .li.init {
  display: none;
  opacity: 0; }

.news-stories-footer .ul .li.pos-0 {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none; }

.news-stories-footer .ul .li.pos-1 {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 1; }

.news-stories-footer .ul .li.pos-2 {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 1; }

.news-stories-footer .ul .li.pos-3 {
  -webkit-transform: translate3d(200%, 0, 0);
  -moz-transform: translate3d(200%, 0, 0);
  -o-transform: translate3d(200%, 0, 0);
  -ms-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 1; }

.news-stories-footer .ul .li.pos-4 {
  -webkit-transform: translate3d(300%, 0, 0);
  -moz-transform: translate3d(300%, 0, 0);
  -o-transform: translate3d(300%, 0, 0);
  -ms-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none; }

.buttons {
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px; }

.buttons .button {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  list-style: none;
  background: #e46f07;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  font-size: 24px; }

.buttons .button:before {
  display: block;
  content: "<";
  color: white; }

.buttons .button + .button {
  margin-left: 32px; }

.buttons .button + .button:before {
  content: ">"; }

.buttons .button:focus {
  outline: dotted 1px #0071ae; }

@media only screen and (min-width: 544px) {
  .news-stories-footer .ul .li {
    width: 50%; } }

@media only screen and (min-width: 992px) {
  .news-stories-footer .ul .li {
    width: 33.3%; } }

.site-footer__wrapper {
  background-color: #e8e4d8;
  padding-top: 35px; }

.site-footer {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28;
  max-width: 1028px;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0 auto;
  color: #464646; }
  .site-footer h2 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.1;
    text-transform: lowercase;
    color: #464646; }
    .site-footer h2 a {
      color: inherit; }
  .site-footer a.icon-link {
    color: #e46f07;
    transition: color 0.15s; }
    .site-footer a.icon-link:hover {
      color: #bb4904;
      text-decoration: none; }
      .site-footer a.icon-link:hover .text {
        text-decoration: underline; }
    .site-footer a.icon-link .text {
      color: #464646;
      margin-left: 0.5em; }
  .site-footer__directions {
    margin-top: 0.5em; }
  .site-footer .directions-link {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.28; }
  .site-footer .subscribe-link {
    display: table;
    width: auto;
    margin: 0 auto; }
    .site-footer .subscribe-link__row {
      display: table-row; }
    .site-footer .subscribe-link__col {
      display: table-cell;
      vertical-align: middle;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.3; }
      .site-footer .subscribe-link__col:first-child {
        width: 30px; }
    .site-footer .subscribe-link .text {
      display: block;
      padding-left: 5px; }
  .site-footer .social-buttons {
    display: block;
    margin: 0.75em 0 1em;
    list-style-type: none;
    padding: 0; }
    .site-footer .social-buttons a {
      color: #7b746b; }
      .site-footer .social-buttons a:hover {
        color: #464646; }
    .site-footer .social-buttons > li {
      display: inline-block;
      margin: 0 0 0.85em 0;
      padding: 0; }
      .site-footer .social-buttons > li:not(:last-child) {
        margin-right: 0.75em; }

.site-footer__address {
  line-height: 1.3; }
  .site-footer__address .org {
    line-height: 1.3;
    display: block;
    margin-bottom: 0.25em; }

.site-footer__columns {
  *zoom: 1; }
  .site-footer__columns:before, .site-footer__columns:after {
    content: " ";
    display: table; }
  .site-footer__columns:after {
    clear: both; }

.org-info .fn {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important; }

.org-info a {
  color: inherit; }

.org-links {
  padding-top: 12px;
  margin-top: 12px; }

.org-link,
.org-links__set a {
  font-size: 29px;
  font-size: 2.9rem;
  line-height: 1.1;
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  color: #c78a3c;
  padding: 5px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.1;
  padding: 0 3px 0;
  margin: 7px 0 9px; }

.org-links__set ul {
  font-size: 0px;
  font-size: 0rem;
  line-height: 0;
  margin: 0;
  padding: 0;
  list-style: none; }

.org-links__set li {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto; }

.org-links__set h2 {
  margin-top: .5em; }

.org-links__set > a,
.org-links__set ul {
  margin-left: -5px; }

.site-footer__column {
  padding: 0 0 25px;
  float: left;
  width: 32%;
  padding: 25px 0 25px; }
  .site-footer__column:first-child {
    padding-top: 25px; }
  .site-footer__column + .site-footer__column {
    padding: 25px 0 25px 2%;
    margin: 0 0 0 2%; }

.donate-cta-wrapper {
  position: relative; }

.donate-cta {
  display: block;
  background: #e46f07;
  color: #fff;
  text-align: center;
  height: 50px;
  padding: 0 1em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 50px;
  transition: background 0.15s;
  border-radius: 5px;
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  position: absolute;
  width: 240px;
  left: 50%;
  top: -25px;
  margin-left: -120px;
  border: 1px solid #b94701; }
  .donate-cta .text {
    margin-left: 0.4em; }
  .donate-cta:hover {
    text-decoration: none !important;
    background: #fb953b; }
  @media (min-width: 768px) {
    .donate-cta {
      width: 350px;
      margin-left: -175px; } }

.site-footer__donate-button {
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  background: #e46f07;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #e46f07;
  text-align: center;
  display: block;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  padding: .75em 1.2em;
  text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: .04em;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: block;
  width: 100%; }
  .site-footer__donate-button:hover {
    background: #f9963e; }
  .site-footer__donate-button .text {
    margin-left: 0.4em; }

.footer-nav ul {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1.25em; }
  .footer-nav ul:first-child {
    margin-right: 45px; }
  .footer-nav ul li {
    display: block; }
    .footer-nav ul li:not(:last-child) {
      margin-bottom: 1.25em; }
  .footer-nav ul a {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.3;
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    color: #464646; }
    .footer-nav ul a:hover {
      text-decoration: underline !important; }

.site-footer__copyright-row {
  *zoom: 1;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.3;
  padding-bottom: 20px; }
  .site-footer__copyright-row:before, .site-footer__copyright-row:after {
    content: " ";
    display: table; }
  .site-footer__copyright-row:after {
    clear: both; }
  .site-footer__copyright-row ul {
    margin: 3px 0 0;
    padding: 0;
    list-style: none;
    display: inline;
    margin-left: 29px; }
  .site-footer__copyright-row li {
    display: inline; }
    .site-footer__copyright-row li + li {
      margin-left: 25px; }
  .site-footer__copyright-row a {
    color: inherit; }

.site-footer__logos,
.site-footer__copyright-text {
  display: inline-block;
  height: 65px;
  position: relative; }

@media (max-width: 767px) {
  .site-footer {
    text-align: center; } }

@media (max-width: 899px) {
  .site-footer__logos,
  .site-footer__copyright-text {
    display: block;
    width: 100%;
    height: auto;
    *zoom: 1;
    text-align: center; }
    .site-footer__logos:before, .site-footer__logos:after,
    .site-footer__copyright-text:before,
    .site-footer__copyright-text:after {
      content: " ";
      display: table; }
    .site-footer__logos:after,
    .site-footer__copyright-text:after {
      clear: both; }
  .site-footer__logos {
    margin-bottom: 20px; } }

@media (min-width: 900px) {
  .site-footer__copyright-text__inner {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    white-space: nowrap; }
  .site-footer__logos {
    float: right; } }

.site-footer__logos a {
  border-bottom: 0 !important; }
  .site-footer__logos a:not(:last-child) img {
    margin-right: 10px; }

.desktop-nav__wrapper {
  background-color: #8f867e;
  height: 36px;
  display: block; }

.desktop-nav {
  max-width: 1000px;
  margin: 0 auto;
  text-transform: lowercase; }
  .desktop-nav ul {
    background: url("../img/desktop-nav-border.png") no-repeat top right;
    font-size: 0;
    line-height: 0;
    height: 36px;
    position: relative; }
    .desktop-nav ul ul {
      display: none;
      position: absolute;
      z-index: 11;
      background: #f5f5f5;
      width: 30%;
      border: 1px solid #d5d2c7;
      border-radius: 4px;
      height: auto; }
      .desktop-nav ul ul:before {
        content: "";
        position: relative;
        top: -5px;
        left: 16%;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 7px 5px 7px;
        border-color: transparent transparent #f5f5f5 transparent; }
      .desktop-nav ul ul li {
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.14;
        display: block;
        background: #f5f5f5; }
        .desktop-nav ul ul li:hover, .desktop-nav ul ul li.here, .desktop-nav ul ul li.parent-here {
          background: #fff; }
        .desktop-nav ul ul li a {
          font-family: 'AvenirNextLTW01-Regular', helvetica, arial, sans-serif;
          font-weight: normal !important;
          font-style: normal !important;
          color: #444;
          text-shadow: none;
          text-align: left;
          padding: .6667em 2em;
          background: none; }
        .desktop-nav ul ul li + li {
          border-top: 1px dashed #d5d2c7; }
  .desktop-nav > ul > li {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.1;
    background: rgba(255, 255, 255, 0.1); }
    .desktop-nav > ul > li.last ul {
      right: 0px; }
      .desktop-nav > ul > li.last ul:before {
        left: 77%; }
    .desktop-nav > ul > li:hover {
      background: rgba(255, 255, 255, 0.2); }
      .desktop-nav > ul > li:hover ul {
        display: block; }
    .desktop-nav > ul > li.here:after, .desktop-nav > ul > li.parent-here:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background: #ffcc40;
      top: 0;
      left: 0;
      position: relative;
      z-index: 10; }
  .desktop-nav a {
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    display: block;
    color: #fff;
    background: url("../img/desktop-nav-border.png") no-repeat;
    overflow: hidden;
    padding: .6667em 0;
    text-shadow: 0 1px 7px rgba(0, 0, 0, 0.4);
    text-align: center; }

.desktop-nav > ul {
  display: table;
  width: 100%; }

.desktop-nav > ul > li {
  display: table-cell; }

.interior-textsizing-and-sharing {
  *zoom: 1;
  background: #fff; }
  .interior-textsizing-and-sharing:before, .interior-textsizing-and-sharing:after {
    content: " ";
    display: table; }
  .interior-textsizing-and-sharing:after {
    clear: both; }

.interior-textsizing-and-sharing--mobile {
  padding: 0 14px 0;
  background: #fff;
  display: none; }

.interior-textsizing-and-sharing--desktop {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 126px;
  font-size: 0;
  line-height: 0; }
  .interior-textsizing-and-sharing--desktop.no-sharing-controls {
    width: 58px; }

.desktop-print-sizing-controls {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  width: 58px;
  padding-bottom: 9px; }

.textsizing-controls,
.sharing-controls,
.sharing-control {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto; }

.textsizing-controls--mobile {
  float: left;
  padding: 13px 13px 8px 0;
  font-size: 0;
  line-height: 0; }

.sharing-controls--mobile {
  float: left;
  padding: 7px 0 0 10px;
  line-height: 0; }

.textsizing-controls--desktop {
  padding-top: 13px; }

.textsizing-controls--mobile {
  background: url("../img/text-sizing-border.png") no-repeat right bottom;
  line-height: 0; }

.sharing-controls--desktop {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  background: url("../img/text-sizing-border-desktop.png") no-repeat left bottom;
  width: 64px;
  padding: 4px 0 13px 15px;
  line-height: 0;
  position: relative; }
  .sharing-controls--desktop:before {
    content: "share:";
    color: #8c8379;
    position: absolute;
    top: -15px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.1; }

.text-sizing-control,
.print-control {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: #8c8379; }

.text-sizing-control {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.1;
  border-bottom: 1px solid #8c8379; }
  .text-sizing-control + .text-sizing-control {
    margin-left: 6px; }

.print-control {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.1; }
  .print-control i {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1; }

.text-sizing-control-small {
  font-size: 11px;
  font-size: 1.1rem;
  position: relative;
  top: 1px; }

.text-sizing-control-regular {
  color: #e46f07;
  border-bottom: 1px solid #e46f07; }

.text-sizing-control-large {
  font-size: 17px;
  font-size: 1.7rem; }

.sharing-control {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.8; }
  .sharing-control a {
    color: #7b746b;
    margin: 0 2px; }
  .sharing-controls--desktop .sharing-control + .sharing-control {
    margin-top: 8px; }

.slabtexted .slabtext {
  display: -moz-inline-box;
  display: inline-block;
  white-space: nowrap; }

.slabtextinactive .slabtext {
  display: inline;
  white-space: normal;
  font-size: 1em !important;
  letter-spacing: inherit !important;
  word-spacing: inherit !important;
  *letter-spacing: 0 !important;
  *word-spacing: 0 !important; }

.slabtextdone .slabtext {
  display: block;
  line-height: 0.9; }

/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none; }

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Icon Fonts
*********************************/
/* Font-face Icons */
@font-face {
  font-family: 'flexslider-icon';
  src: url("../fonts/flexslider-icon.eot");
  src: url("../fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"), url("../fonts/flexslider-icon.woff") format("woff"), url("../fonts/flexslider-icon.ttf") format("truetype"), url("../fonts/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0; }

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block; }

.flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block; }

.flexslider {
  margin: 0;
  position: relative;
  zoom: 1; }
  .flexslider .slides {
    zoom: 1; }

.flex-viewport {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
  max-height: 2000px; }
  .loading .flex-viewport {
    max-height: 300px; }

.flex-direction-nav {
  width: 100%; }
  .flex-direction-nav .flex-prev,
  .flex-direction-nav .flex-next {
    width: 29px;
    height: 84px;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
    position: absolute;
    top: 50px; }
  .flex-direction-nav .flex-prev {
    background-image: url("../img/flexslider-arrow-prev.png");
    left: 0;
    margin-left: -34px; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
      .flex-direction-nav .flex-prev {
        background-image: url("../img/flexslider-arrow-prev@2x.png");
        background-size: 29px 84px; } }
  .flex-direction-nav .flex-next {
    background-image: url("../img/flexslider-arrow-next.png");
    right: 0;
    margin-right: -34px; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
      .flex-direction-nav .flex-next {
        background-image: url("../img/flexslider-arrow-next@2x.png");
        background-size: 29px 84px; } }

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/_ui/js/vendor/fancybox/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/_ui/js/vendor/fancybox/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/_ui/js/vendor/fancybox/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/_ui/js/vendor/fancybox/fancybox_overlay.png"); }

.rgba .fancybox-overlay {
  background: rgba(68, 68, 68, 0.65); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
  visibility: hidden;
  position: relative;
  text-shadow: none;
  z-index: 8050;
  text-shadow: 0 -1px 1px rgba(68, 68, 68, 0.65); }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  top: 102%;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  color: #FFF; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/_ui/js/vendor/fancybox/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/_ui/js/vendor/fancybox/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

.main-content-well__calendar-float div {
  margin: 0 auto; }

.main-content-well__calendar-float--top {
  display: none;
  float: right;
  margin: 0 0 1em 1em;
  display: block; }
  .main-content-well__calendar-float--top div {
    margin: 0 !important; }

.main-content-well__calendar-float--bottom {
  display: none; }

.grid-icon-buttons {
  *zoom: 1;
  margin-top: 20px;
  margin-bottom: -15px; }
  .grid-icon-buttons:before, .grid-icon-buttons:after {
    content: " ";
    display: table; }
  .grid-icon-buttons:after {
    clear: both; }

.grid-icon-button {
  display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 5px;
  background: white;
  padding: 5px;
  margin-right: 15px;
  margin-bottom: 12px; }
  .grid-icon-button .icon {
    color: #e46f07;
    transition: color 0.15s; }
  .grid-icon-button .text {
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    font-size: 11px;
    line-height: 1;
    margin-top: 8px;
    color: #969089;
    transition: color 0.15s; }
  .grid-icon-button .centered {
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%); }
  .grid-icon-button:hover .icon {
    color: #bb4904; }
  .grid-icon-button:hover .text {
    color: #726e6a; }

.grid-icon-button__inner {
  display: block;
  width: 100%;
  height: 100%;
  color: #969089;
  border-radius: 5px;
  box-shadow: inset -2px 0px 2px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative; }

@media (min-width: 481px) and (max-width: 600px) {
  .grid-icon-button {
    width: 22.375%;
    margin-right: 3.5%; }
    .grid-icon-button:nth-child(4n+4) {
      margin-right: 0; } }

@media (min-width: 361px) and (max-width: 480px) {
  .grid-icon-button {
    width: 31%;
    margin-right: 3.5%; }
    .grid-icon-button:nth-child(3n+3) {
      margin-right: 0; } }

@media (max-width: 360px) {
  .grid-icon-button {
    width: 48%;
    margin-right: 4%; }
    .grid-icon-button:nth-child(2n+2) {
      margin-right: 0; } }

.grid-logos {
  *zoom: 1;
  margin-top: 20px;
  margin-bottom: -15px;
  text-align: center; }
  .grid-logos:before, .grid-logos:after {
    content: " ";
    display: table; }
  .grid-logos:after {
    clear: both; }

.grid-logo {
  display: inline-block;
  margin-bottom: 15px; }
  .grid-logo img {
    display: block;
    margin: 0;
    max-width: 100%;
    height: auto; }
  .grid-logo:not(:last-child) {
    margin-right: 20px; }

.staff.h2 {
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 16px; }

.person-container {
  display: flex;
  flex-wrap: wrap; }

.person-container .person {
  width: 100%;
  padding: 16px;
  text-align: center;
  cursor: pointer; }

.person-container .person:focus {
  outline: dotted 1px #e46f07; }

.person-container .person .img {
  display: block;
  width: 100%; }

.person-container .person .h3 {
  margin-top: 8px; }

.person-container .person .p {
  font-size: 16px;
  margin: 4px 0 0 0;
  padding: 0; }

.person-container .person .p.email {
  font-size: 14px;
  word-break: break-word; }

.person-container .person-bio {
  display: none;
  padding: 16px; }

.person-container .person-bio .person-bio-wrapper {
  padding: 16px;
  background: white;
  position: relative; }

.person-container .person-bio .header {
  margin-bottom: 32px;
  padding-right: 36px; }

.person-container .person-bio .h4 {
  font-size: 32px;
  display: inline-block;
  position: relative; }

.person-container .person-bio .h4:after {
  content: "";
  display: block;
  width: 50%;
  height: 3px;
  position: absolute;
  bottom: -16px;
  background: white; }

.person-container .person-bio .button.close {
  position: absolute;
  right: 16px;
  top: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: white; }

.person-container .person-bio .button.close:after {
  content: "X";
  color: #e46f07;
  font-size: 24px;
  display: block;
  font-family: 'Avenir Next LT W01 Bold',helvetica,arial,sans-serif; }

.list-container {
  margin-top: 48px; }

.list-container .person-list {
  margin-top: 24px;
  margin-bottom: 24px; }

.list-container .person-list h3 {
  margin-bottom: 12px;
  color: #444; }

.list-container .person-list .p {
  color: #444;
  margin: 0; }

@media only screen and (min-width: 544px) {
  .person-container .person {
    width: 50%; } }

@media only screen and (min-width: 768px) {
  .person-container .person {
    width: 33%; } }

.homepage-hero a {
  text-decoration: none; }

.homepage-hero__orange-text {
  font-family: 'ClarendonTextW01-BoldIt', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.3;
  color: #e7c45a;
  text-shadow: 2px 0 2px rgba(0, 0, 0, 0.55); }

.homepage-hero__small-text,
.homepage-hero__large-text {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  display: block;
  color: #fff;
  text-shadow: 2px 0 2px rgba(0, 0, 0, 0.55); }

.homepage-hero__large-text {
  font-size: 31px;
  font-size: 3.1rem;
  line-height: 1; }

.homepage-hero__small-text {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.41;
  margin-top: 4px;
  letter-spacing: .05em; }

.homepage-hero__content {
  -webkit-font-smoothing: antialiased; }

.mobile-hero {
  *zoom: 1;
  position: relative;
  min-height: 212px;
  display: none; }
  .mobile-hero:before, .mobile-hero:after {
    content: " ";
    display: table; }
  .mobile-hero:after {
    clear: both; }

.mobile-hero__image-slide {
  display: block;
  float: left;
  max-width: 100%; }

.mobile-hero__content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0 14px 16px; }

.mobile-homepage-cta {
  padding: 0 14px 28px;
  padding-top: 1em;
  background-color: #f6f5f4;
  display: none; }
  .mobile-homepage-cta p {
    margin-top: 0; }

.homepage-cta a {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  background: #e46e05;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #b94701;
  text-align: center;
  display: block;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  padding: .75em 1.2em;
  text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: .04em;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }
  .homepage-cta a:hover {
    background: #fb953b; }

.desktop-hero {
  *zoom: 1;
  display: none;
  position: relative;
  overflow: hidden;
  min-height: 206px;
  display: block;
  overflow: visible; }
  .desktop-hero:before, .desktop-hero:after {
    content: " ";
    display: table; }
  .desktop-hero:after {
    clear: both; }

.desktop-hero__image-slide {
  display: block;
  float: left;
  width: 180%;
  margin-left: -40%; }

.hero-container {
  position: relative; }

.hero-container .hero-navigation {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  z-index: 3;
  height: 48px;
  justify-content: center;
  align-items: center;
  display: none; }

.hero-container .hero-navigation.ready {
  display: flex; }

.hero-container .hero-navigation button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #e46f07;
  border: solid 2px white;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0.5; }

.hero-container .hero-navigation button.featured {
  opacity: 1; }

.hero-container .hero-navigation button + button {
  margin-left: 1rem; }

.slide-container {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s; }

.slide-container.first {
  position: relative;
  opacity: 1;
  transition: opacity 1s; }

.slide-container.ready {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s; }

.slide-container.ready.render {
  opacity: 1;
  z-index: 2;
  transition: opacity 1s; }

.desktop-hero__content {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 0 14px 32px;
  width: 42%;
  padding: 0 14px 48px;
  padding: 0 14px 70px; }

.desktop-hero__content__blurb-and-button {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.3;
  display: none;
  color: #fff;
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.3; }

.homepage-cta.homepage-cta--desktop {
  *zoom: 1; }
  .homepage-cta.homepage-cta--desktop:before, .homepage-cta.homepage-cta--desktop:after {
    content: " ";
    display: table; }
  .homepage-cta.homepage-cta--desktop:after {
    clear: both; }
  .homepage-cta.homepage-cta--desktop a {
    width: 65%;
    float: right; }

.homepage-buttons-intro {
  *zoom: 1;
  background-color: #f6f5f4;
  padding: 27px 14px 35px;
  border-top: 1px solid #d3d1c6;
  padding: 0 14px 35px;
  border-top: none;
  padding: 2.25em 14px 35px;
  padding: 36px 0; }
  .homepage-buttons-intro:before, .homepage-buttons-intro:after {
    content: " ";
    display: table; }
  .homepage-buttons-intro:after {
    clear: both; }

.homepage-buttons {
  *zoom: 1;
  float: right;
  width: 32.5%; }
  .homepage-buttons:before, .homepage-buttons:after {
    content: " ";
    display: table; }
  .homepage-buttons:after {
    clear: both; }
  .homepage-buttons ul {
    *zoom: 1;
    margin: 0 -5px 0 -5px;
    padding: 0;
    list-style: none; }
    .homepage-buttons ul:before, .homepage-buttons ul:after {
      content: " ";
      display: table; }
    .homepage-buttons ul:after {
      clear: both; }
  .homepage-buttons li {
    width: 48%;
    height: 108px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.3;
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    float: left;
    background: #fff;
    border-radius: 7px;
    padding: 4px;
    margin: 1%; }
  .homepage-buttons a {
    display: block;
    width: 100%;
    height: 100%;
    color: #969089;
    border-radius: 5px;
    box-shadow: inset -2px 0px 2px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 4px; }
  .homepage-buttons img {
    margin: 3px 0 2px; }
  .homepage-buttons span {
    display: block; }
  .homepage-buttons .single-line {
    line-height: 2; }
  .homepage-buttons .double-line {
    line-height: 1; }
  .homepage-buttons ul {
    margin: 0; }
  .homepage-buttons li {
    width: 24%;
    height: 114px;
    margin: 0; }
    .homepage-buttons li + li {
      margin-left: 1%; }
  .homepage-buttons li {
    width: 48%;
    height: 121px;
    margin: 0; }
    .homepage-buttons li + li {
      margin-left: 0; }
    .homepage-buttons li.button-1 {
      margin: 0 4% 5% 0; }
    .homepage-buttons li.button-3 {
      margin: 0 4% 5% 0; }

.homepage-intro {
  margin-top: 24px;
  letter-spacing: -.005em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.35;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.35;
  float: left;
  width: 63.8%;
  margin-top: 0; }
  .homepage-intro h2 {
    margin: 0;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.1; }
  .homepage-intro h2 {
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 1.1; }
  .homepage-intro p {
    margin-top: .75em; }

.homepage-intro__button {
  margin: 2em 0 0 0 !important; }
  .homepage-intro__button a {
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    background: #e46e05;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #b94701;
    text-align: center;
    display: block;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    padding: .75em 1.2em;
    text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: .04em;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out; }
    .homepage-intro__button a:hover {
      background: #fb953b; }
  .homepage-intro__button a {
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    background: #e46e05;
    font-size: 0.61em;
    border-radius: 5px;
    border: 1px solid #b94701;
    text-align: center;
    display: inline;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    padding: .75em 1.2em;
    text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: .04em;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out; }
    .homepage-intro__button a:hover {
      background: #fb953b; }

.homepage-tweets {
  padding: 0 14px 14px; }
  .homepage-tweets ul {
    *zoom: 1;
    margin: 0;
    padding: 0;
    list-style: none; }
    .homepage-tweets ul:before, .homepage-tweets ul:after {
      content: " ";
      display: table; }
    .homepage-tweets ul:after {
      clear: both; }
  .homepage-tweets li {
    margin: 0;
    padding: 0;
    float: left;
    width: 32%;
    padding-bottom: 28px; }
    .homepage-tweets li + li {
      margin-left: 2%; }
  .homepage-tweets li + li {
    margin-top: 1.9em;
    margin-top: 0; }

.homepage-tweets__title {
  *zoom: 1;
  margin-bottom: .75em;
  margin-bottom: 1em; }
  .homepage-tweets__title:before, .homepage-tweets__title:after {
    content: " ";
    display: table; }
  .homepage-tweets__title:after {
    clear: both; }
  .homepage-tweets__title h2 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.25; }
  .homepage-tweets__title iframe {
    margin-top: .5em; }
  .homepage-tweets__title h2 {
    display: inline; }
  .homepage-tweets__title iframe {
    float: right;
    margin-top: 0; }

.homepage-tweet {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.4;
  font-family: 'ClarendonTextW01-Regula 1097896', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  background: #fff;
  color: #78736b;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 6px 6px 3px rgba(0, 0, 0, 0.04); }
  .homepage-tweet p {
    margin: 0; }
  .homepage-tweet a {
    font-family: georgia;
    font-weight: bold; }

.homepage-tweet__triangle {
  width: 32px;
  height: 28px;
  background-image: url("../img/tweet-triangle.jpg");
  position: relative;
  top: 0;
  left: 18px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
    .homepage-tweet__triangle {
      background-image: url("../img/tweet-triangle@2x.jpg");
      background-size: 32px 28px; } }

.homepage-tweet__user-info {
  *zoom: 1;
  padding-top: 5px; }
  .homepage-tweet__user-info:before, .homepage-tweet__user-info:after {
    content: " ";
    display: table; }
  .homepage-tweet__user-info:after {
    clear: both; }

.homepage-tweet__user-info__image {
  float: left;
  padding-right: .75em; }
  .homepage-tweet__user-info__image img {
    width: 40px;
    height: auto;
    border-radius: 4px; }

.homepage-tweet__user-info__text h3 {
  font-family: 'ClarendonTextW01-Italic 1097339', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.1; }

.homepage-tweet__user-info__text span {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.1;
  color: #78736b;
  text-transform: uppercase; }

.homepage-tweet__user-info a {
  display: block; }

.interior__page-body {
  font-size: 1em;
  line-height: 1.5; }

.full-width .main__wrapper {
  background: #fff; }

.interior--two-column,
.interior--three-column {
  padding: 26px 0;
  *zoom: 1; }
  .interior--two-column:before, .interior--two-column:after,
  .interior--three-column:before,
  .interior--three-column:after {
    content: " ";
    display: table; }
  .interior--two-column:after,
  .interior--three-column:after {
    clear: both; }

@media (max-width: 599px) {
  .interior--two-column,
  .interior--three-column {
    padding-top: 20px; }
  body.news-events .interior--two-column,
  body.news-events .interior--three-column {
    padding-top: 0; } }

@media (max-width: 1040px) {
  .interior--two-column,
  .interior--three-column {
    margin-left: 14px;
    margin-right: 14px; } }

.main-content-well {
  *zoom: 1; }
  .main-content-well:before, .main-content-well:after {
    content: " ";
    display: table; }
  .main-content-well:after {
    clear: both; }

.side-column-content {
  margin-top: 40px;
  margin-bottom: 40px; }

.side-column-media-contact {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.4;
  color: #7b746b; }
  .side-column-media-contact h2 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.3; }

.main-content-well--two-column {
  float: right;
  width: 74.5%; }

.side-column--two-column {
  float: left;
  width: 23%; }

.three-column--two-thirds-wrapper {
  float: right;
  width: 72%; }

.main-content-well--three-column {
  float: left;
  width: 71%; }

.right-column--three-column {
  float: right;
  width: 24%;
  padding-top: 2em; }

.side-column--three-column {
  float: left;
  width: 25%;
  padding-top: 2em; }

.large-intro-image {
  height: 235px;
  position: relative;
  overflow: hidden; }
  .large-intro-image .large-intro-image__inner {
    margin: 0 auto;
    max-width: 1200px;
    height: 235px; }
    .large-intro-image .large-intro-image__inner img {
      position: absolute;
      height: 235px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 9; }
  .large-intro-image .large-intro-image__text-container {
    position: relative;
    max-width: 1028px;
    padding: 0 28px;
    height: 100%;
    z-index: 10;
    margin: 0 auto; }
  .large-intro-image .large-intro-image__text {
    width: 285px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.16;
    text-shadow: 2px 0 2px rgba(0, 0, 0, 0.55); }
  @media (min-width: 768px) {
    .large-intro-image {
      height: 320px; }
      .large-intro-image .large-intro-image__inner {
        height: 320px; }
        .large-intro-image .large-intro-image__inner img {
          height: 320px; }
      .large-intro-image .large-intro-image__text {
        text-align: left;
        left: auto;
        right: 14px;
        transform: translate(0, -50%);
        font-size: 28px;
        font-size: 2.8rem;
        line-height: 1.16; } }
  @media (min-width: 1024px) {
    .large-intro-image {
      height: 366px; }
      .large-intro-image .large-intro-image__inner {
        height: 366px; }
        .large-intro-image .large-intro-image__inner img {
          height: 366px; }
      .large-intro-image .large-intro-image__text {
        font-size: 31px;
        font-size: 3.1rem;
        line-height: 1.16; } }

.interior--sidebar {
  *zoom: 1;
  margin: 37px auto 44px;
  max-width: 1028px;
  overflow: hidden;
  padding: 0 14px;
  margin-top: 20px; }
  .interior--sidebar:before, .interior--sidebar:after {
    content: " ";
    display: table; }
  .interior--sidebar:after {
    clear: both; }
  .interior--sidebar .right-sidebar {
    background: white;
    border-radius: 7px;
    padding: 17px 20px;
    margin-top: 30px;
    position: relative; }
    .interior--sidebar .right-sidebar p:first-child {
      margin-top: 0 !important; }
    .interior--sidebar .right-sidebar p:last-child {
      margin-bottom: 0 !important; }
    .interior--sidebar .right-sidebar h3 {
      font-size: 22px;
      font-size: 2.2rem;
      line-height: 1.1;
      margin-bottom: 7px; }
    .interior--sidebar .right-sidebar .sidebar-section {
      margin-bottom: 30px; }
      .interior--sidebar .right-sidebar .sidebar-section p:first-of-type {
        margin-top: 0; }
    .interior--sidebar .right-sidebar .sidebar-footer {
      padding-top: 19px;
      margin-top: 30px;
      border-top: 1px solid #ddd; }
  @media (min-width: 850px) {
    .interior--sidebar {
      margin-top: 37px; }
      .interior--sidebar .main-content-well {
        display: inline-block;
        width: calc(100% - 305px);
        float: left; }
      .interior--sidebar .right-sidebar {
        display: inline-block;
        width: 275px;
        float: right;
        margin-top: 0; }
      .interior--sidebar .sidebar-footer.fixed {
        position: absolute;
        left: 20px;
        bottom: 0;
        padding: 17px 0;
        width: calc(100% - 40px); } }
  @media (min-width: 950px) {
    .interior--sidebar .main-content-well {
      width: calc(100% - 355px); }
    .interior--sidebar .right-sidebar {
      width: 325px; } }

.main-content-well__page-subtitle h1 {
  font-size: 1.25em;
  line-height: 1.2;
  margin-bottom: .7em; }

.main-content-well__bordered-intro-image {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  background: #fff;
  padding: 5px;
  max-width: 100%;
  border-radius: 6px;
  margin-bottom: 20px; }
  .main-content-well__bordered-intro-image img {
    max-width: 100%;
    height: auto;
    border-radius: 6px; }

.ct-button {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  background: #e46e05;
  font-size: 0.875em;
  border-radius: 5px;
  border: 1px solid #b94701;
  text-align: center;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  padding: .75em 1.2em;
  text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: .04em;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }
  .ct-button:hover {
    background: #fb953b; }

.main-content-well {
  font-size: 1em; }

.main-content-well__rich-text {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.375; }
  .main-content-well__rich-text h2 {
    font-size: 1.125em;
    line-height: 1.15;
    margin-top: 2em; }
  .main-content-well__rich-text p {
    margin: 1em 0; }
    .main-content-well__rich-text p:first-of-type {
      margin-top: 0; }

.side-navigation {
  margin: 56px -14px 0 -14px;
  padding: 22px 14px 0;
  border-top: 1px solid #d6d4ca;
  text-transform: lowercase;
  margin: 0;
  padding: 0;
  border-top: none; }
  .side-navigation li li a {
    color: #444; }
  .side-navigation li a {
    font-family: 'AvenirNextLTW01-Regular', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    font-size: 1em;
    line-height: 1.15em;
    padding: 8px 0;
    color: #444;
    display: block;
    border-bottom: 1px dashed #d3d1c6; }
    .side-navigation li a:hover {
      color: #e46f07; }
  .side-navigation li.overview > a {
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    padding-bottom: 20px; }
  .side-navigation li.here > a,
  .side-navigation li.parent-here > a {
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    color: #e46f07 !important; }
  .side-navigation li li {
    padding-left: 1em;
    font-size: .75em; }

.search-results {
  margin: 1.5em 0;
  padding: 0;
  list-style: none; }
  .search-results h4 {
    margin-bottom: .33em; }
  .search-results li + li {
    margin-top: 1em; }

.pagination {
  font-family: 'ClarendonTextW01-Regula 1097896', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.3;
  *zoom: 1;
  position: relative;
  margin: 0;
  padding: 1em 0 0;
  list-style: none;
  text-align: center; }
  .pagination:before, .pagination:after {
    content: " ";
    display: table; }
  .pagination:after {
    clear: both; }
  .pagination li {
    display: inline; }
  .pagination a {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    padding: 0 .5em;
    min-width: 27px;
    height: 27px;
    text-align: center;
    line-height: 25px;
    border-radius: 4px;
    border: 1px solid #d5d2c7; }
    .pagination a.active {
      font-family: 'Clarendon Text W01 Bold', georgia, serif;
      font-weight: normal !important;
      font-style: normal !important;
      border: none;
      color: #9a918a; }
    .pagination a.other-page:hover, .pagination a.page-previous:hover, .pagination a.page-next:hover {
      background: #0071ae;
      color: #fff;
      border: 1px solid #0071ae; }

.page-previous {
  position: absolute;
  left: 0;
  top: 1em; }

.page-next {
  position: absolute;
  right: 0;
  top: 1em; }

.right-column .org-links__set i {
  color: #8c8379; }

.main-content-well__rich-text table th {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  background-color: #e46f07;
  color: #fff;
  padding: 10px;
  text-align: left; }

.main-content-well__rich-text table td {
  padding: 3px 10px; }

blockquote {
  margin: 0;
  padding: 0 0 0 1.25em;
  border-left: 5px solid #e46f07;
  color: #666; }

.rich-text-body__right-aligned-image-caption,
.rich-text-body__left-aligned-image-caption {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #777; }
  .rich-text-body__right-aligned-image-caption img,
  .rich-text-body__left-aligned-image-caption img {
    max-width: 100%; }

.rich-text-body__right-aligned-image-caption {
  float: right;
  width: 30%;
  margin: 1em 0 1em 1em; }

.rich-text-body__left-aligned-image-caption {
  float: left;
  width: 30%;
  margin: 1em 1em 1em 0; }

.freeform-row label {
  text-transform: lowercase; }

.freeform-row .unit {
  padding: 0 !important; }

.freeform-row input[type="text"],
.freeform-row textarea {
  width: 80% !important; }

.freeform-row input[type="submit"],
.freeform-row button[type="submit"] {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  background: #e46e05;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #b94701;
  text-align: center;
  display: block;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  padding: .75em 1.2em;
  text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: .04em;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }
  .freeform-row input[type="submit"]:hover,
  .freeform-row button[type="submit"]:hover {
    background: #fb953b; }

.freeform-row .error-message {
  color: #dd0000; }

.inner-table-wrapper {
  min-width: 100%;
  overflow: auto;
  overflow-x: auto; }

.desktop-nav__interior-page-header-content__wrapper {
  padding: 0 14px 0;
  background: #fff url("../img/thin-top-shadow.png") repeat-x top left; }

.desktop-nav__interior-page-header-content {
  display: none;
  position: relative;
  padding: 16px 0 0;
  max-width: 1000px;
  margin: 0 auto;
  display: block; }
  .desktop-nav__interior-page-header-content h1 {
    font-size: 35px;
    font-size: 3.5rem;
    line-height: 1.07;
    padding: 3px 0 13px;
    width: 69%;
    width: 73%;
    width: 81%; }

.interior-page-header-home-link {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 0;
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  color: #9a918a;
  position: relative;
  top: -8px; }

.top-image img {
  width: 100%;
  height: auto; }

.top-image--desktop {
  background: #fff;
  display: none;
  overflow: hidden;
  max-width: 1000px;
  margin: 0 auto;
  overflow: visible; }
  .top-image--desktop img {
    display: block;
    width: 120%;
    margin-left: -10%; }
    @media print {
      .top-image--desktop img {
        width: 100% !important;
        margin-left: 0 !important; } }

.top-image--mobile {
  display: none; }

.top-image--desktop {
  display: block; }

.top-cta-area {
  margin: 0 14px .75em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  border-bottom: 1px solid #f2c459;
  margin-bottom: .75em;
  display: none; }
  .top-cta-area p {
    margin-bottom: .75em; }
  .top-cta-area a {
    color: #0071ae; }

.page-intro__cta-area {
  display: none;
  display: block; }

.page-intro {
  padding: 1.5em 14px 1.5em;
  *zoom: 1;
  position: relative;
  padding: 1em 14px 1em; }
  .page-intro:before, .page-intro:after {
    content: " ";
    display: table; }
  .page-intro:after {
    clear: both; }

.page-intro__content-wrapper {
  position: relative;
  max-width: 1000px;
  margin: 0 auto; }

.page-intro__title h1 {
  font-size: 1.25em;
  line-height: 1.2; }

.page-intro__image-and-caption__image-wrapper {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  background: #fff;
  padding: 5px;
  max-width: 100%;
  border-radius: 6px; }
  .page-intro__image-and-caption__image-wrapper img {
    max-width: 100%;
    height: auto;
    border-radius: 6px; }

.page-intro__image-and-caption__caption {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.333;
  color: #898278; }
  .page-intro__image-and-caption__caption p {
    margin: .5em 0 0; }

.program-intro .page-intro__side-wrapper {
  padding-top: 10px; }

.page-intro__side-wrapper {
  float: right;
  margin: 0 0 1em 1em; }
  .page-intro__side-wrapper.page-intro__side-wrapper--before {
    display: none;
    display: block; }
  .page-intro__side-wrapper.page-intro__side-wrapper--after {
    display: none; }

.image-nav-list-page .page-intro__side-wrapper {
  display: block !important;
  margin-bottom: -1.5em;
  text-align: center;
  margin-bottom: -1em;
  position: absolute;
  bottom: -1em;
  right: 30px; }

.image-nav-list-page .page-intro__body {
  padding-right: 220px; }

.statslider {
  padding: 30px 14px 30px;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px; }
  .statslider > h1 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.333;
    margin-bottom: 22px; }
  .statslider ul {
    padding: 0; }
  .statslider .slides > li {
    margin-right: 27px; }
    .statslider .slides > li + li {
      margin-left: 27px; }
    .statslider .slides > li:last-child {
      margin-right: 0; }

.statslider__slide__figure,
.statslider__slide__text {
  border-top: 1px solid #f9e6aa; }

.statslider__slide__figure {
  color: #f2c459;
  padding: 24px 0;
  text-align: center;
  letter-spacing: .1em; }
  .statslider__slide__figure b, .statslider__slide__figure strong {
    letter-spacing: -.08em; }
  .statslider__slide__figure span {
    display: block; }

.statslider__slide__text {
  font-family: 'ClarendonTextW01-Regula 1097896', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #7b746b; }

.statslider__slide__link {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.43; }
  .statslider__slide__link a {
    color: #e46f07; }

.side-promo-image {
  width: 80%;
  margin-top: 22px; }

.nav-lists {
  *zoom: 1;
  margin-left: -1%;
  margin-right: -1%; }
  .nav-lists:before, .nav-lists:after {
    content: " ";
    display: table; }
  .nav-lists:after {
    clear: both; }

nav.simple-nav-list {
  background: #fff;
  border-radius: 12px;
  padding: 6px;
  margin-bottom: 32px;
  float: left;
  margin: 1%;
  width: 48%; }
  nav.simple-nav-list h1 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.1;
    padding: 25px 23px 22px;
    border-bottom: 1px solid #d3d1c6; }
  nav.simple-nav-list ul {
    padding: 0 23px 15px; }
  nav.simple-nav-list a {
    display: block;
    padding-top: 15px; }
    nav.simple-nav-list a:after {
      content: " »"; }

.simple-nav-list-wrapper {
  border: 1px solid #d3d1c6;
  border-radius: 7px; }

.image-nav-list {
  *zoom: 1;
  padding: 1em 14px 1em; }
  .image-nav-list:before, .image-nav-list:after {
    content: " ";
    display: table; }
  .image-nav-list:after {
    clear: both; }
  .image-nav-list h1 {
    font-size: 1.125em;
    line-height: 1.333;
    margin-bottom: .66em; }
  .image-nav-list > ul {
    display: flex;
    flex-wrap: wrap; }
  .image-nav-list li {
    margin-top: 1em; }
  .image-nav-list li {
    padding: 1%;
    width: 50%; }
  .image-nav-list li {
    padding: 2%;
    width: 33%; }
  .image-nav-list > ul {
    flex-wrap: nowrap; }
  .image-nav-list li {
    padding: 1%;
    width: auto; }

.image-nav-list__image {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  background: #fff;
  padding: 5px;
  max-width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 34px 34px rgba(213, 210, 199, 0.35);
  margin-bottom: 14px;
  box-shadow: 0 0 10px 10px rgba(213, 210, 199, 0.35); }
  .image-nav-list__image img {
    max-width: 100%;
    height: auto;
    border-radius: 6px; }

.image-nav-list__link,
.image-nav-list__blurb {
  font-family: 'ClarendonTextW01-Regula 1097896', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: .8125em;
  line-height: 1.5;
  color: #78736b; }
  .image-nav-list__link a,
  .image-nav-list__blurb a {
    color: #e46f07; }
  .image-nav-list__link p,
  .image-nav-list__blurb p {
    margin-top: .5em; }

.gift-amounts {
  *zoom: 1;
  margin: 21px 0;
  position: relative; }
  .gift-amounts:before, .gift-amounts:after {
    content: " ";
    display: table; }
  .gift-amounts:after {
    clear: both; }
  .gift-amounts:not(.other-amount) .gift-amount-other {
    display: none; }

.gift-amount-boxes {
  margin-bottom: 15px; }

.gift-amount {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  height: 80px;
  position: relative; }
  .gift-amount label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 80px;
    color: white;
    transition: background 0.15s;
    background: #9A928B;
    border-radius: 7px;
    cursor: pointer;
    height: 80px; }
    .gift-amount label:hover {
      background: #7b746a; }
  .gift-amount input[type="radio"] {
    visibility: hidden;
    width: 0;
    height: 0; }
  .gift-amount input:checked + label {
    background: #E46F07; }
  @media (max-width: 639px) {
    .gift-amount {
      width: calc(33.33333% - 6px);
      margin-right: 9px;
      margin-bottom: 9px; }
      .gift-amount:nth-child(3n) {
        margin-right: 0; } }
  @media (min-width: 640px) {
    .gift-amount {
      width: calc(16.66667% - 7.5px); }
      .gift-amount:not(:last-child) {
        margin-right: 9px; } }

.gift-amount-other {
  display: block;
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  top: 0; }
  .gift-amount-other .icon {
    position: absolute;
    z-index: 10; }
    .gift-amount-other .icon.dollar-symbol {
      color: #4D4D4D;
      font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
      font-weight: normal !important;
      font-style: normal !important;
      font-size: 36px;
      font-size: 3.6rem;
      line-height: 1;
      position: absolute;
      top: 22px;
      left: 27px; }
    .gift-amount-other .icon.cancel-other-amount {
      border: 0;
      background: transparent;
      margin: 0;
      padding: 0 20px;
      right: 0;
      color: #9A928B;
      font-size: 30px;
      line-height: 80px;
      transition: color 0.15s;
      cursor: pointer; }
      .gift-amount-other .icon.cancel-other-amount:hover {
        color: #7b746a; }
  .gift-amount-other input[type="number"] {
    position: relative;
    z-index: 9;
    background: white;
    border: 1px solid #9A928B;
    border-radius: 7px;
    height: 80px;
    width: 100%;
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 1;
    padding: 22px 64px;
    color: #444; }
    .gift-amount-other input[type="number"]::-webkit-inner-spin-button, .gift-amount-other input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  @media (min-width: 640px) {
    .gift-amount-other {
      width: calc(83.3333% - 1px); } }

.donate-form fieldset {
  margin-bottom: 20px; }
  .donate-form fieldset.text input {
    margin-bottom: 12px; }

.donate-form .required {
  color: #FC0006;
  font-family: 'AvenirNextLTW01-Regular', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important; }

.donate-form .donate-form-label {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.125;
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  margin-bottom: 7px; }

.donate-form input[type="text"],
.donate-form input[type="email"] {
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 28px;
  padding: 0 7px;
  border: 1px solid #ccc;
  background: white; }

.donate-form textarea {
  display: block;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 22px;
  padding: 7px;
  border: 1px solid #ccc;
  background: white;
  height: 45px;
  min-height: 45px;
  resize: vertical; }

.donate-form .dedication-fields {
  padding-left: 40px; }
  .donate-form .dedication-fields .dedication-fields__row {
    *zoom: 1; }
    .donate-form .dedication-fields .dedication-fields__row:before, .donate-form .dedication-fields .dedication-fields__row:after {
      content: " ";
      display: table; }
    .donate-form .dedication-fields .dedication-fields__row:after {
      clear: both; }
  .donate-form .dedication-fields .dedication-field {
    margin-bottom: 12px; }
    .donate-form .dedication-fields .dedication-field .donate-form-error {
      margin-top: 5px; }
  .donate-form .dedication-fields .dedication-fields__inner {
    background: #e8e8e8;
    padding: 20px 20px 8px 20px;
    *zoom: 1;
    position: relative; }
    .donate-form .dedication-fields .dedication-fields__inner:before, .donate-form .dedication-fields .dedication-fields__inner:after {
      content: " ";
      display: table; }
    .donate-form .dedication-fields .dedication-fields__inner:after {
      clear: both; }
    .donate-form .dedication-fields .dedication-fields__inner:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 11px 0 0 11px;
      border-color: transparent transparent transparent #e8e8e8;
      position: absolute;
      top: -11px;
      left: 0; }
  .donate-form .dedication-fields .dedication-fields__type select {
    font-size: 15px;
    width: 100%; }
  .donate-form .dedication-fields .dedication-fields__name input {
    width: 100%; }

.donate-form .donate-form__footer-text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-top: 14px; }

.donate-form .donate-form-error {
  color: #FC0006;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 15px; }
  .donate-form .donate-form-error.hidden {
    display: none; }

.donate-form .donor-name-row .donate-form-error {
  padding-top: 12px; }

.donate-form .donor-name-row .donor-name-col:first-child {
  margin-bottom: 20px; }

@media (min-width: 640px) {
  .donate-form .dedication-fields .col {
    display: inline-block;
    float: left;
    margin-right: 5%; }
  .donate-form .dedication-fields .one-third {
    width: 25%; }
  .donate-form .dedication-fields .two-thirds {
    width: 70%; }
  .donate-form .dedication-fields .half {
    width: 47.5%; }
  .donate-form .dedication-fields .last {
    margin-right: 0; }
  .donate-form .dedication-fields__city {
    width: 50%; }
  .donate-form .dedication-fields__state {
    width: 10%; }
  .donate-form .dedication-fields__zip-code {
    width: 30%; }
  .donate-form .donor-name-row .donor-name-col {
    display: inline-block;
    float: left;
    width: 47.5%;
    margin-bottom: 0 !important; }
    .donate-form .donor-name-row .donor-name-col:not(:last-child) {
      margin-right: 5%; } }

.donate-form-submit {
  background: #E46F07;
  border-radius: 7px;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 32px;
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  border: 0;
  padding: 9px 50px;
  color: white;
  transition: background 0.15s; }
  .donate-form-submit .loading-img {
    margin-right: 5px;
    display: none; }
  .donate-form-submit:hover {
    background: #fa943c; }
  .donate-form-submit.loading {
    background: #7b746a;
    padding-left: 32px; }
    .donate-form-submit.loading .loading-img {
      display: inline; }
    .donate-form-submit.loading:hover {
      background: #7b746a; }

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .checkbox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    background: white;
    border: 1px solid #ccc;
    transition: all 0.15s;
    color: white;
    text-align: center; }
  .checkbox-container:hover input ~ .checkmark,
  .checkbox-container:focus input ~ .checkmark {
    background-color: #ccc; }
  .checkbox-container input:checked ~ .checkmark {
    background: #E46F07;
    border-color: #E46F07;
    color: white; }
  .checkbox-container.large-checkbox {
    padding-left: 40px;
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 26px; }
    .checkbox-container.large-checkbox .checkmark {
      width: 26px;
      height: 26px;
      border-radius: 5px;
      font-size: 18px;
      line-height: 26px; }

.checkbox-instructions {
  padding-left: 40px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5; }

.donate-thank-you .social-buttons {
  margin: 1.5em 0;
  padding: 0; }
  .donate-thank-you .social-buttons li {
    display: inline-block;
    font-size: 20px; }
    .donate-thank-you .social-buttons li:not(:last-child) {
      margin-right: 0.6em; }

.donate-thank-you .social-buttons a,
.donate-thank-you .subscribe-link {
  color: #e46f07;
  transition: color 0.15s; }
  .donate-thank-you .social-buttons a:hover,
  .donate-thank-you .subscribe-link:hover {
    color: #bb4904;
    text-decoration: none; }

.donate-thank-you .subscribe-link {
  display: block;
  margin: 1.5em 0; }
  .donate-thank-you .subscribe-link .fa-envelope {
    font-size: 29px;
    margin-right: 8px; }
  .donate-thank-you .subscribe-link .fa-envelope,
  .donate-thank-you .subscribe-link .text {
    vertical-align: middle;
    line-height: 20px; }
  .donate-thank-you .subscribe-link:hover .text {
    text-decoration: underline; }

.paypal-error {
  background: #FC0006;
  padding: 20px 25px;
  margin-bottom: 20px; }
  .paypal-error h3 {
    margin-bottom: 10px;
    color: #fff; }
  .paypal-error p {
    color: #fff;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 0; }
  .paypal-error a {
    color: #fff;
    text-decoration: underline; }

.donate-box__cta a {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  background: #e46f07;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #b94701;
  text-align: center;
  display: block;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  padding: .75em 1.2em;
  text-shadow: 1px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: .04em;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 100%; }

.donate-box__cta a:hover {
  background: #fb953b;
  text-decoration: none; }

.main-content-well__donate-box {
  position: relative; }

.donate-box__bg {
  z-index: 5; }

.donate-box__overlay {
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 6;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 6px; }

.donate-box__content {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 54%;
  width: 40%;
  height: 100%;
  color: #fff; }

.donate-box__text {
  display: block;
  margin-top: 35px;
  font-size: 18px;
  line-height: 26px;
  height: calc(100% - 140px);
  position: relative; }
  .donate-box__text__inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
    .donate-box__text__inner p {
      margin: 0; }

.donate-box__cta a {
  position: absolute;
  bottom: 40px;
  left: 0; }

.donate-box__overlay {
  opacity: 1;
  background: -moz-linear-gradient(left, transparent 20%, black 100%);
  background: -webkit-linear-gradient(left, transparent 20%, black 100%);
  background: linear, to right, transparent 20%, black 100%;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); }

.main-content-well__back-link {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.3;
  margin-bottom: 1em; }
  .main-content-well__back-link a {
    color: #9a918a; }

.photo-gallery-grid {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  line-height: 0;
  margin-right: -4px;
  margin-right: -13px; }
  .photo-gallery-grid li {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    background: #fff;
    border-radius: 9px;
    padding: 4px;
    margin: 0 4px 4px 0;
    margin: 0 13px 19px 0; }
  .photo-gallery-grid img {
    border-radius: 7px;
    width: 132px;
    height: auto;
    width: 212px; }

.media-center__featured-video__title h2 {
  font-family: 'ClarendonTextW01-BoldIt', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.133;
  margin-top: 24px; }

.media-center__featured-video__teaser {
  font-size: 23px;
  font-size: 2.3rem;
  line-height: 1.34783; }
  .media-center__featured-video__teaser p {
    margin: 6px 0 0; }

.media-center__featured-video__link {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.3; }
  .media-center__featured-video__link p {
    margin-top: 14px; }

.page-list-container {
  background: #fff;
  margin: 2em -14px 0;
  padding: 20px;
  box-shadow: 0 0 34px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 2em 0 0; }
  .page-list-container.no-top-margin {
    margin-top: 0 !important; }
  .page-list-container.no-top-margin {
    margin-top: 0 !important; }

.page-list-container__header {
  margin-bottom: 11px;
  padding-bottom: 14px;
  margin-bottom: 0; }
  .page-list-container__header h2 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.2;
    display: inline; }

.page-list-container__filter-options {
  *zoom: 1;
  padding: 20px 0 15px;
  border-top: 1px solid #f2c459;
  border-bottom: 1px solid #f2c459;
  display: none; }
  .page-list-container__filter-options:before, .page-list-container__filter-options:after {
    content: " ";
    display: table; }
  .page-list-container__filter-options:after {
    clear: both; }

.filter-options__label {
  font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  float: left;
  width: 20%;
  text-transform: uppercase;
  font-size: 11px;
  color: #9a918a;
  display: inline; }

.filter-options {
  float: right;
  width: 75%;
  margin-bottom: 5px; }
  .filter-options select {
    width: 100%; }

body.media-center .side-navigation,
body.news-events .side-navigation {
  margin-top: 0; }

body.media-center .interior--two-column,
body.news-events .interior--two-column {
  padding-bottom: 0 !important; }

.media-center__list-items {
  *zoom: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #f2c459; }
  .media-center__list-items:before, .media-center__list-items:after {
    content: " ";
    display: table; }
  .media-center__list-items:after {
    clear: both; }
  .media-center__list-items li {
    *zoom: 1;
    padding: 24px 0;
    border-top: 1px solid #f2c459; }
    .media-center__list-items li:before, .media-center__list-items li:after {
      content: " ";
      display: table; }
    .media-center__list-items li:after {
      clear: both; }
    body.media-center .media-center__list-items li {
      float: left;
      height: 240px;
      width: 50%;
      padding: 3% 2%; }
      body.media-center .media-center__list-items li.odd {
        border-right: 1px solid #f2c459; }
    .media-center__list-items li.desktop-only {
      display: none;
      display: list-item; }

.media-center__list-item__image {
  float: left; }

.media-center__list-item__info {
  padding-left: 90px; }

.media-center__list-item__type {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.3;
  font-family: 'ClarendonTextW01-Italic 1097339', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  color: #a69b8f;
  margin-bottom: 6px; }
  .media-center__list-item__type i {
    padding-left: 4px; }

.media-center__list-item__title h3 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
  margin-bottom: 0; }

.media-center__list-item__teaser p {
  margin: 6px 0 0; }

.inline-filter-options {
  padding-top: .25em; }
  .media-center .inline-filter-options {
    display: none; }
  .inline-filter-options label {
    font-family: 'Avenir Next LT W01 Bold', helvetica, arial, sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
    text-transform: uppercase;
    font-size: 11px;
    color: #9a918a;
    padding-right: .75em; }
  .media-center .inline-filter-options {
    display: inline;
    float: right; }
    .media-center .inline-filter-options label {
      padding-left: 2em; }

h3.dark {
  color: #464646; }

h3.thin {
  font-family: 'AvenirNextLTW01-Regular', helvetica, arial, sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.1; }

body.news-events .inline-filter-options {
  float: none; }

body.news-events .page-list-container {
  margin-top: 0;
  box-shadow: none;
  margin-top: 2em;
  box-shadow: 0 0 34px rgba(0, 0, 0, 0.2);
  border-top: none; }

body.news-events .interior--three-column {
  padding-top: 0;
  padding-bottom: 0; }

.news-events .inline-filter-options span {
  padding-left: 0; }

right-column--three-column,
.right-column--three-column h2 {
  font-family: 'ClarendonTextW01-Regula 1097896', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important; }

.right-column--three-column {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.3;
  color: #7b746b; }
  .right-column--three-column h2 {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.3; }

.news-event-detail__byline {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.2;
  font-family: 'ClarendonTextW01-Italic 1097339', georgia, serif;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: lowercase;
  color: #a69b8f;
  margin-bottom: 10px; }
  .news-event-detail__byline .bull {
    margin: 0 2px; }

.news-event-detail__title {
  text-transform: lowercase; }
  .news-event-detail__title h1 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.25; }

.media-center__list-item__type,
.media-center__list-item__title {
  text-transform: lowercase; }
  .media-center__list-item__type .bull,
  .media-center__list-item__title .bull {
    margin: 0 2px; }

.media-center__list-item__teaser {
  margin-top: 4px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.375; }

.inline-sharing-controls {
  display: block;
  margin: 10px 0 15px;
  height: 24px; }
  .inline-sharing-controls__label {
    display: inline-block;
    color: #8c8379;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 24px;
    margin: 1px 4px 0 0; }
  .inline-sharing-controls__control {
    display: inline-block; }
    .inline-sharing-controls__control a {
      display: inline-block;
      font-size: 24px;
      font-size: 2.4rem;
      line-height: 24px;
      color: #7b746b;
      margin: 0 3px;
      vertical-align: top; }

.block {
  margin-bottom: 20px; }
  .block:last-of-type {
    margin-bottom: 30px; }
  .block.heading {
    margin-top: 2em;
    margin-bottom: 1em; }
    .block.heading:first-child {
      margin-top: 0; }
  .block.horizontal-line {
    display: block;
    border: 0;
    background-color: #fed850;
    width: 100%;
    height: 1px; }

.centered-image {
  text-align: center; }
  .centered-image img {
    border-radius: 6px; }

.body-text ul ul, .body-text ol ol {
  margin: 0.25em 0; }

.aligned-image img {
  border-radius: 6px; }

.image-caption {
  margin-top: 0.5em;
  font-size: 14px;
  line-height: 1.25;
  text-align: center; }

@media (max-width: 640px) {
  .aligned-image {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    text-align: center; }
    .aligned-image img {
      margin: 0 auto; }
    .aligned-image .image-caption {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto; } }

@media (min-width: 640px) {
  .aligned-image {
    width: 360px; }
    .aligned-image.alignment-left {
      float: left;
      margin: 0.25em 30px 20px 0; }
    .aligned-image.alignment-right {
      float: right;
      margin: 0.25em 0 20px 30px; } }

@media print {
  * {
    box-shadow: none !important;
    text-shadow: none !important; }
  main, body {
    background: transparent !important;
    color: #000;
    /* Black prints faster: h5bp.com/s */ }
  a,
  a:visited {
    text-decoration: underline; }
  /*
	 * Don't show links for images, or javascript/internal links
	 */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .side-column--two-column,
  .interior-textsizing-and-sharing {
    display: none; }
  .main-content-well--two-column {
    float: none;
    width: 100%; } }
