@media print {
	* {
		box-shadow: none !important;
		text-shadow: none !important;
	}

	main, body {
		background: transparent !important;
		color: #000; /* Black prints faster: h5bp.com/s */
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	/*
	 * Don't show links for images, or javascript/internal links
	 */

	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	.side-column--two-column,
	.interior-textsizing-and-sharing {
		display:none;
	}

	.main-content-well--two-column {
		float:none;
		width:100%;
	}
}